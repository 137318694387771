<div class="outerSpace legalBar">
	<div class="legalBar-wrapper">
		<span class="legalBar-highlight"
			>&copy; Woolworths New Zealand Limited {{ currentYear }} - all rights reserved.</span
		>
		<ul>
			<li *ngFor="let link of links">
				<a [href]="link.url" maybeExternal [maybeExternalCurrentTab]="externalLinksInCurrentTab$ | async">{{ link.label }}</a>
			</li>
		</ul>
	</div>
</div>
