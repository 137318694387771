import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import {
	FeatureService,
	FlagKey,
	FlagService,
	NavigationItemGroupWithAnalytics,
	MegaMenuRecipeVariants,
} from '@woolworthsnz/styleguide';
import { ContextResponse } from '@woolworthsnz/trader-api';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import { tealiumUtagBrowseMenuPromoTile } from '../../constants/tealium-events.constants';

@Component({
	selector: 'global-nav-feature-recipe-stamp-component',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './feature-recipe-stamp-component.component.html',
	styleUrls: ['./feature-recipe-stamp-component.component.scss'],
})
export class FeatureRecipeStampComponentComponent implements OnInit {
	@Input()
	public aislesNavItems$ = new BehaviorSubject<NavigationItemGroupWithAnalytics[] | undefined>(undefined);
	@Input()
	public shelvesNavItems$ = new BehaviorSubject<NavigationItemGroupWithAnalytics[] | undefined>(undefined);
	@Input()
	public width: number;
	public enableRecipeStamp$: Observable<boolean>;
	public flagVariation$: Observable<string>;
	public variants = MegaMenuRecipeVariants;
	public aisles = ['Pantry', 'Fridge & Deli', 'Frozen'];
	public shelves = ['Oil, Vinegar & Condiments', 'Vegan & Vegetarian', 'Frozen Vegetables'];

	constructor(
		private featureService: FeatureService,
		private flagService: FlagService,
		private tealiumUtagService: TealiumUtagService
	) {}

	ngOnInit(): void {
		this.enableRecipeStamp$ = this.featureService.isEnabled(
			ContextResponse.EnabledFeaturesEnum.EnableFeatureRecipeStamp
		);
		this.flagVariation$ = this.flagService.getVariationKey(FlagKey.megaMenuRecipeExperiment);
	}

	onPromoTileClicked(aisle: string, shelve: string, variation: string): void {
		const creativeName = shelve ? `Browse Menu | ${aisle} | ${shelve}` : `Browse Menu | ${aisle}`;

		this.tealiumUtagService.link(
			{
				...tealiumUtagBrowseMenuPromoTile,
				ecommerce: {
					creative_name: creativeName,
					creative_slot: '4',
					promotion_id: '4',
					promotion_name: `Cook with Macro ${variation}`,
				},
			},
			true
		);
	}
}
