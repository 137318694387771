import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { ChangeOrderService, VIEW_TROLLEY_PATH, REVIEW_ORDER_PATH } from '@woolworthsnz/shop';
import {
	BreakPointService,
	CustomWindow,
	Device,
	FeatureService,
	LocalStorageService,
	ModalOverlayService,
	ModalSize,
	PopupService,
	ShopperService,
	StorageKeys,
	SVGIcon,
	ButtonComponent,
	PopupTargetDirective,
	PopupComponent,
	SvgIconComponent,
	TRADER_BASE_URL,
	WINDOW,
} from '@woolworthsnz/styleguide';
import { ContextResponse } from '@woolworthsnz/trader-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgIf, AsyncPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'global-nav-change-order-bar',
	templateUrl: './change-order-bar.component.html',
	styleUrls: ['./change-order-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, PopupComponent, ButtonComponent, PopupTargetDirective, SvgIconComponent, AsyncPipe]
})
export class ChangeOrderBarComponent {
	iconHint = SVGIcon.Hint;
	isMobileDevice = false;
	enabledChangedPriceTutorial = false;

	orderDisplay$: Observable<string>;

	constructor(
		private changeOrderService: ChangeOrderService,
		private shopperService: ShopperService,
		private modalOverlayService: ModalOverlayService,
		private router: Router,
		protected popupService: PopupService,
		private breakPointService: BreakPointService,
		private featureService: FeatureService,
		private localStorageService: LocalStorageService,
		@Inject(WINDOW) private window: CustomWindow,
		@Optional() @Inject(TRADER_BASE_URL) private traderBaseUrl?: string
	) {
		this.breakPointService.device$.pipe(takeUntilDestroyed()).subscribe((device: Device) => {
			this.isMobileDevice = device === Device.MOBILE;
		});

		this.enabledChangedPriceTutorial = this.featureService.isFeatureEnabled(
			ContextResponse.EnabledFeaturesEnum.ChangeOrderChangedPriceTutorial
		);

		this.orderDisplay$ = this.shopperService.state$.pipe(
			map(({ changingOrderId }) => (changingOrderId ? `order CD${changingOrderId}` : 'your order'))
		);
	}

	showCancelChangeOrderModal(): void {
		this.modalOverlayService.openGenericModal({
			buttonText: 'Cancel edits',
			title: 'Cancel Change Order',
			description:
				'Are you sure you want to cancel the changes to your order? By cancelling, any changes you have made will not be saved.',
			hasCancelButton: true,
			cancelButtonText: 'Continue with edits',
			icon: undefined,
			modalSize: ModalSize.Medium,
			ctaAction: () => {
				this.changeOrderService.cancelChangeOrder().add(() => {
					if (this.traderBaseUrl) {
						this.window.location.href = `${this.traderBaseUrl}/`;
					} else {
						this.router.navigateByUrl('/');
					}
				});
			},
		});
	}

	handlePriceChangeTutorialBack(): void {
		this.setIgnoreChangedPriceTutorialAcknowledgementToFalse();
		this.popupService.close('priceChangePopupStepTwo');
		this.popupService.open('priceChangePopupStepOne');
	}

	handleHintIconClick(): void {
		this.setIgnoreChangedPriceTutorialAcknowledgementToTrue();

		// Tutorial can be shown only when we're on ViewTrolley or ReviewOrder page
		if (this.router.url.indexOf(VIEW_TROLLEY_PATH) === -1 && this.router.url.indexOf(REVIEW_ORDER_PATH) === -1) {
			this.navigateToViewTrolleyPage();
		}

		this.popupService.open('priceChangePopupStepOne');
	}

	navigateToViewTrolleyPage(): void {
		if (this.traderBaseUrl) {
			this.window.location.href = `${this.traderBaseUrl}/${VIEW_TROLLEY_PATH}`;
		} else {
			this.router.navigateByUrl(VIEW_TROLLEY_PATH);
		}
	}

	setIgnoreChangedPriceTutorialAcknowledgementToTrue(): void {
		this.localStorageService.setItem(StorageKeys.ignoreChangedPriceTutorialAcknowledgement, 'true');
	}

	setIgnoreChangedPriceTutorialAcknowledgementToFalse(): void {
		this.localStorageService.setItem(StorageKeys.ignoreChangedPriceTutorialAcknowledgement, 'false');
	}
}
