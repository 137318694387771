import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Optional,
	Output,
	TrackByFunction,
	ViewChild,
} from '@angular/core';
import {
	ACCOUNT_BASE_URL,
	AppSettingsService,
	NotificationType,
	ShopperService,
	TrackingData,
	TrackingEvent,
	ButtonComponent,
	MaybeExternalLinkDirective,
	PopupComponent,
	PopupTargetDirective,
	SvgIconComponent,
	TrackEventDirective,
} from '@woolworthsnz/styleguide';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SignInComponent } from '../sign-in/sign-in.component';
import { MyAccountComponent } from '../my-account/my-account.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { EDR_LANDING_PAGE_PATH } from '@woolworthsnz/shop';

@Component({
	selector: 'global-nav-quick-nav',
	templateUrl: './quick-nav.component.html',
	styleUrls: ['./quick-nav.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		NgIf,
		NgFor,
		MaybeExternalLinkDirective,
		ButtonComponent,
		TrackEventDirective,
		PopupComponent,
		PopupTargetDirective,
		SvgIconComponent,
		MyAccountComponent,
		SignInComponent,
		AsyncPipe,
	],
})
export class QuickNavComponent implements OnInit {
	@Input() externalLinksInCurrentTab = false;
	@Output() logout = new EventEmitter<void>();
	@ViewChild('rewardsModalTarget') public rewardsModalTarget: ElementRef<HTMLLIElement>;

	public quickNavLinks: { url: string; text: string; target?: string }[] = [];
	public onecardLink: { url: string; text: string; target?: string };
	public everydayRewardsLink: { url: string; text: string; target?: string };
	public isLoggedIn$: Observable<boolean>;
	public firstName$: Observable<string | undefined>;
	public popupTrackingLoggedIn = { name: 'account panel', value: 'account panel popup' };
	public popupTrackingLoggedOut = { name: 'sign in or register', value: 'sign in or register popup' };
	public refreshAccountMenu = new Subject<boolean>();

	constructor(
		private appSettingsService: AppSettingsService,
		private shopperService: ShopperService,
		@Optional() @Inject(ACCOUNT_BASE_URL) private accountBaseUrl: string
	) {
		this.isLoggedIn$ = this.shopperService.state$.pipe(map((state) => !!state.isLoggedIn));
		this.firstName$ = this.shopperService.state$.pipe(map((state) => state.firstName));

		this.onecardLink = {
			url: `${this.accountBaseUrl ?? ''}/onecard`,
			text: 'Onecard',
			target: 'self',
		};

		this.everydayRewardsLink = {
			url: `${this.accountBaseUrl ?? ''}/${EDR_LANDING_PAGE_PATH}`,
			text: 'Everyday Rewards',
			target: 'self',
		};
	}

	public trackByFn: TrackByFunction<{ url: string; text: string }> = (_, i) => i.url;

	public handleLogout(): void {
		this.logout.emit();
	}

	public ngOnInit(): void {
		this.quickNavLinks = this.appSettingsService.getSetting('navs').quickNav;
	}

	public getTrackingData(name: string): TrackingData {
		return { event: TrackingEvent.NotificationEvent, type: NotificationType.Global, name };
	}

	handleModalOpen(open: boolean): void {
		this.refreshAccountMenu.next(open);
	}
}
