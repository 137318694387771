import { Inject, Injectable, Optional } from '@angular/core';
import { FulfilmentStoreService } from '@woolworthsnz/fulfilment';
import { ShellService } from '@woolworthsnz/shop';
import { NavigationMenuItemWithAnalytics, ShopperService, TRADER_BASE_URL } from '@woolworthsnz/styleguide';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationMenuItem } from '@woolworthsnz/trader-api';
import { addAnalyticsNameToMainNav, addDomain, filterNavsBySessionGroup, sortMainNavItemsById } from '../helpers';
import { getBrowseNavItems, getFilteredNavItemsForBrowseMenuExperiment } from '../helpers/main-nav.helper';

@Injectable({
	providedIn: 'root',
})
export class NavItemsService {
	public navItems$: Observable<NavigationMenuItemWithAnalytics[]>;
	public browseNavItem$: Observable<NavigationMenuItemWithAnalytics | undefined>;
	public filteredNavItemsShownInBrowse$: Observable<NavigationMenuItemWithAnalytics[]>;
	public dynamicNavItem$: Observable<NavigationMenuItemWithAnalytics | undefined>;
	private _isInitialised = false;

	constructor(
		private shellService: ShellService,
		private shopperService: ShopperService,
		private fulfilmentStoreService: FulfilmentStoreService,
		@Optional() @Inject(TRADER_BASE_URL) public baseDomain: string
	) {}

	get isInitialised(): boolean {
		return this._isInitialised;
	}

	init(): void {
		if (!this._isInitialised) {
			this.navItems$ = combineLatest([
				this.shellService.state$,
				this.shopperService.state$,
				this.fulfilmentStoreService.state$,
			]).pipe(
				map(([shellState, shopperState, fulfilmentState]) =>
					filterNavsBySessionGroup(
						shellState.mainNavs,
						shopperState.sessionGroups,
						fulfilmentState.expressFulfilment?.isExpressSlot
					)
				),
				map((mainNavs: NavigationMenuItem[]) => addDomain(mainNavs, this.baseDomain)),
				map(sortMainNavItemsById),
				map(addAnalyticsNameToMainNav)
			);

			this.dynamicNavItem$ = this.shellService.select('dynamicHeaderLink').pipe(
				map((dynamicHeaderLink) =>
					dynamicHeaderLink?.isEnabled
						? {
								url: dynamicHeaderLink.url,
								label: dynamicHeaderLink.label,
								analyticsName: dynamicHeaderLink.label,
								iconUrl: '/images/global-nav/nav-experiment-v2/campaign.png',
						  }
						: undefined
				)
			);

			this.browseNavItem$ = getBrowseNavItems(this.navItems$, this.baseDomain);
			this.filteredNavItemsShownInBrowse$ = getFilteredNavItemsForBrowseMenuExperiment(this.navItems$);
			this._isInitialised = true;
		}
	}
}
