import { Location, PopStateEvent, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, OnDestroy } from '@angular/core';
import { AutocompleteComponent } from '@woolworthsnz/form';
import {
	AppSettingsService,
	BreakPointService,
	CustomWindow,
	ModalOverlayRef,
	ModalOverlayService,
	MultiSuggestion,
	WINDOW,
	AutocompleteModalComponent,
	OptionListMultiComponent,
	OptionListComponent,
} from '@woolworthsnz/styleguide';
import { v4 as uuidv4 } from 'uuid';
import { PortalModule } from '@angular/cdk/portal';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'global-nav-search-form-autocomplete',
	providers: [ModalOverlayService],
	templateUrl: './search-form-autocomplete.component.html',
	styleUrls: ['./search-form-autocomplete.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [PortalModule, NgIf, OptionListComponent, AutocompleteModalComponent, OptionListMultiComponent],
})
export class SearchFormAutocompleteComponent extends AutocompleteComponent implements OnDestroy {
	@Input() subnav: ElementRef;
	@Input() header = '';
	isSmallScreen = false;
	uuid: string;
	private loseFocusOnBack = true;
	private destroy$: Subject<boolean> = new Subject();

	constructor(
		protected modalOverlayService: ModalOverlayService,
		private breakpointService: BreakPointService,
		private appSettingsService: AppSettingsService,
		private location: Location,
		@Inject(WINDOW) private window: CustomWindow
	) {
		super(modalOverlayService);
		this.uuid = uuidv4();
	}

	set overlayVisible(v: boolean) {
		if (this.appSettingsService.settings.isEmbeddedInApp && this._overlayVisible !== v) {
			if (v) {
				this.location.go(this.location.path(), '', { [`${this.uuid}_autocompleteModalVisible`]: true });
			} else {
				const state: any = this.location.getState();
				// due to the async nature of the location.go the modal may not have been opened in time - so check state and close via history only if necessary
				if (state && state[`${this.uuid}_autocompleteModalVisible`]) {
					this.loseFocusOnBack = false;
					this.location.back();
				} else {
					this.overlay?.close();
				}
			}
		}
		this._overlayVisible = v;
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.breakpointService.isSmallDevice$.pipe(takeUntil(this.destroy$)).subscribe((isSmallDevice) => {
			this.isSmallScreen = isSmallDevice;
		});

		if (this.appSettingsService.settings.isEmbeddedInApp) {
			this.location.subscribe((value: PopStateEvent) => {
				if (value.state && !value.state[`${this.uuid}_autocompleteModalVisible]`]) {
					this.overlay?.close();
					if (this.loseFocusOnBack) {
						this.autocompleteInput.input?.nativeElement?.blur();
					}
					// reset the flag to true so if it comes through browser it blurs by default
					this.loseFocusOnBack = true;
				}
			});
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}

	protected setResults(results: any[] | MultiSuggestion[], isMulti = false): void {
		if (results) {
			if (isMulti) {
				this.multiAutoCompleteResults = results;
			} else {
				this.autoCompleteResults = results;
			}

			if (this.isSmallScreen) {
				const backdrop = this.appSettingsService.settings.isEmbeddedInApp;
				this.modalOverlayService.setState({
					element: this.subnav,
					hasBackdrop: backdrop,
					isConnectedElement: true,
					backdropClass: 'overlay--transparent',
					scrollStrategy: 'block',
				});
			} else {
				this.modalOverlayService.setState({
					element: this.autocompleteInputTarget,
					hasBackdrop: false,
					isConnectedElement: true,
					backdropClass: 'overlay--transparent',
					scrollStrategy: 'reposition',
					width: this.autocompleteInputTarget.offsetWidth,
				});
			}
			let mor: ModalOverlayRef | undefined;
			if (!this.overlayVisible && results.length) {
				this.overlayVisible = true;
				mor = this.modalOverlayService.open({
					templateRef: this.overlayTemplate,
					skipTracking: true,
				});
			}

			/**
			 * NB. This is a special case for when we use the backdrop in the app in order to make use of history. We need to use the backdrop to trigger the history back event, but still want the users intended interaction to occur afterwards. So we wait for the async locartion.back (600ms) and then figure out what they wanted to click on without the backdrop in the way - and then click it.
			 */
			if (this.appSettingsService.settings.isEmbeddedInApp) {
				mor?.backdropClick$.pipe(takeUntil(this.destroy$)).subscribe((ev: MouseEvent) => {
					setTimeout(() => {
						this.window.document.elementFromPoint(ev.pageX, ev.pageY)?.click();
					}, 600);
				});
			}
		}
	}
}
