import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import {
	getTrackingData,
	NavigationItemGroupWithAnalytics,
	MaybeExternalLinkDirective,
	NotificationType,
	TrackingData,
	TrackEventDirective,
} from '@woolworthsnz/styleguide';
import { Observable } from 'rxjs/internal/Observable';
import { GlobalNavSettingsService } from '../../services/global-nav-settings.service';
import { GlobalNavSettings } from '../../services/global-nav.settings.interface';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';

@Component({
	selector: 'global-nav-links',
	template: `
		<div *ngFor="let linkGroup of linkGroups">
			<h4 *ngIf="linkGroup.label">{{ linkGroup.label }}</h4>
			<ul *ngIf="globalNavSettings$ | async as globalNavSettings">
				<li *ngFor="let link of linkGroup.items">
					<div *ngIf="link.iconUrl"><img [src]="link.iconUrl" alt="" /></div>
					<a
						[href]="link.url"
						maybeExternal
						[maybeExternalCurrentTab]="globalNavSettings.externalLinksInCurrentTab"
						[cdxTrackEvent]="trackingData(trackingName || linkGroup.label, link.label)"
						(click)="navItemClicked.next()"
						>{{ link.label }}
					</a>
				</li>
			</ul>
		</div>
	`,
	styleUrls: ['./links.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgFor, NgIf, MaybeExternalLinkDirective, TrackEventDirective, AsyncPipe]
})
export class LinksComponent implements OnInit {
	@Input()
	linkGroups: NavigationItemGroupWithAnalytics[];

	@Input()
	trackingName: string;

	@HostBinding('attr.data-orientation')
	@Input()
	orientation: 'horizontal' | 'vertical' = 'horizontal';

	@HostBinding('attr.data-fill')
	@Input()
	fill: 'green' | 'dark' = 'green';

	@HostBinding('attr.data-item-spacing')
	@Input()
	itemSpacing: 'normal' | 'dense' = 'normal';

	@Output()
	navItemClicked = new EventEmitter<void>();

	globalNavSettings$: Observable<GlobalNavSettings>;

	constructor(protected globalNavSettings: GlobalNavSettingsService) { }

	ngOnInit(): void {
		this.globalNavSettings$ = this.globalNavSettings.getSettings();
	}

	public trackingData(name: string, value: string): TrackingData {
		return getTrackingData(name, NotificationType.Global, value);
	}
}
