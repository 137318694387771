import { Injectable } from '@angular/core';
import { ApiService, AppSettingsService, SearchType, StatefulService } from '@woolworthsnz/styleguide';
import { EMPTY, Observable, Subject, Subscription } from 'rxjs';
import { share, take, tap } from 'rxjs/operators';

interface SearchSuggestionState {
	topProductSuggestions?: string[];
	searchSuggestions?: any;
}

@Injectable({
	providedIn: 'root',
})
export class SearchSuggestionsService extends StatefulService<SearchSuggestionState> {
	searchSuggestionResponse: Subject<any> = new Subject();
	topSuggestionsRequest: Observable<string[]>;

	constructor(private appSettingsService: AppSettingsService, private apiService: ApiService) {
		super({ topProductSuggestions: [] });

		this.topSuggestionsRequest = this.apiService.get(this.productSearchSuggestionsEndpoint).pipe(
			tap((res) => this.setState({ topProductSuggestions: res })),
			share()
		);
	}

	get productSearchSuggestionsEndpoint(): string {
		return this.appSettingsService.getEndpoint('productSearchSuggestions');
	}

	get recipeSearchSuggestionsEndpoint(): string {
		return this.appSettingsService.getEndpoint('recipeSearchSuggestions');
	}

	fetchTopProductSuggestions(): Observable<string[]> {
		return this.topSuggestionsRequest;
	}

	fetchSearchSuggestions = (typedValue: string, searchType: SearchType): Subscription => {
		if (typedValue.trim() === '') {
			return EMPTY.subscribe();
		}

		typedValue = encodeURIComponent(typedValue);
		let endpoint = `${this.productSearchSuggestionsEndpoint}?term=${typedValue}`;
		if (searchType === SearchType.Recipes) {
			endpoint = `${this.recipeSearchSuggestionsEndpoint}?term=${typedValue}`;
		}
		return this.apiService
			.get(endpoint, {})
			.pipe(take(1))
			.subscribe((r) => this.searchSuggestionResponse.next(r));
	};
}
