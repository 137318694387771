import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FormModule } from '@woolworthsnz/form';
import { FulfilmentLibModule } from '@woolworthsnz/fulfilment';
import { StyleguideModule } from '@woolworthsnz/styleguide';

import {
	BasketTotalsComponent,
	BrowseMenuComponent,
	BrowseMenuItemsComponent,
	BrowseNavComponent,
	ChangeOrderBarComponent,
	FamilyBarComponent,
	FeatureProductStampComponent,
	FeatureRecipeStampComponentComponent,
	FooterComponent,
	FooterLinksComponent,
	HeaderComponent,
	LegalBarComponent,
	LinksComponent,
	MainNavComponent,
	MobileNavComponent,
	MyAccountComponent,
	NavComponent,
	QuickNavComponent,
	SearchComponent,
	SearchFormAutocompleteComponent,
	SignInComponent,
	SocialsComponent,
} from '..';

const components = [
	BasketTotalsComponent,
	BrowseMenuComponent,
	BrowseMenuItemsComponent,
	BrowseNavComponent,
	ChangeOrderBarComponent,
	FamilyBarComponent,
	FooterComponent,
	FooterLinksComponent,
	HeaderComponent,
	LegalBarComponent,
	LinksComponent,
	MainNavComponent,
	MobileNavComponent,
	MyAccountComponent,
	NavComponent,
	QuickNavComponent,
	SignInComponent,
	SearchComponent,
	SearchFormAutocompleteComponent,
	SocialsComponent,
	FeatureProductStampComponent,
	FeatureRecipeStampComponentComponent,
];

@NgModule({
	imports: [
		CommonModule,
		StyleguideModule,
		RouterModule,
		FulfilmentLibModule,
		FormModule,
		FormsModule,
		ReactiveFormsModule,
		PortalModule,
		...components,
	],
	exports: [...components],
})
export class GlobalNavLibModule {}
