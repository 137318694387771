<ng-container>
	<div class="browseMenu" #browseDiv>
		<section class="departments" [ngClass]="{ 'departments--hidden': hideDepartmentsInMobile }">
			<div *ngIf="showTopLevelBackButton" class="canDismiss">
				<button
					cdxButton
					fillStyle="primaryInverse"
					fullWidth="true"
					class="back-link"
					startSlotSize="small"
					[size]="'default'"
					[startSlotActive]="true"
					i18n="@@browseMenu-backToHamburgerMenu"
					(click)="handleBackButtonClick()"
				>
					<cdx-svg-icon name="chevron-left" fill="green" size="small" start></cdx-svg-icon>
					Back
				</button>
				<button
					aria-label="Dismiss browse menu"
					type="button"
					cdxButton
					fillStyle="link"
					i18n="@@CloseBrowseMenuButton"
					(click)="onClose()"
				>
					<cdx-svg-icon name="cross" size="extra-small" fill="dark"></cdx-svg-icon>
				</button>
			</div>
			<global-nav-browse-menu-items
				#departments
				class="firstColumn"
				[showTitle]="false"
				[linkGroups]="browseNavItems"
				[trackingName]="trackingName"
				[hasIconRight]="true"
				[currentIndex]="currentDepartmentIndex"
				[selectedItem]="currentDepartment"
				[canDismiss]="!isDesktopNav"
				(closeButtonClicked)="onClose()"
				(itemClicked)="onDepartmentClick($event)"
				(mouseoverItem)="hoverExperimentActive && onDepartmentHover($event)"
				[hoverExperimentActive]="hoverExperimentActive"
				[isDesktopNav]="isDesktopNav"
				[isNewBrowseMenuLinksEnabled]="isNewBrowseMenuLinksEnabled$ | async"
				globalNavMenuKeyboardNavigation
				[isKeyboardNavigationFocused]="isOpen && currentLevel === currentBrowseLevel.Departments"
				[menuName]="currentBrowseLevel.Departments"
				(navigateToPriorMenuItemEvent)="navigateToPriorMenuItem($event)"
				(navigateToNextMenuItemEvent)="navigateToNextMenuItem($event)"
				(navigateToFirstMenuItemEvent)="navigateToFirstMenuItem($event)"
				(navigateToLastMenuItemEvent)="navigateToLastMenuItem($event)"
				[currentMenuItemIndex]="currentDepartmentIndex"
			></global-nav-browse-menu-items>
		</section>
		<section *ngIf="isDesktopNav; else mobileNav" class="aislesAndShelves">
			<global-nav-browse-menu-items
				*ngIf="aislesNavItems$ | async as aislesNavItems"
				class="aisles"
				[showTitle]="false"
				[linkGroups]="aislesNavItems"
				[hasIconRight]="true"
				[currentIndex]="currentAisleIndex"
				[selectedItem]="currentAisle"
				(shopAllClicked)="onShopAllClicked('Aisle')"
				(itemClicked)="onAisleClick($event)"
				(mouseoverItem)="hoverExperimentActive && onAisleHover($event)"
				[hoverExperimentActive]="hoverExperimentActive"
				[isDesktopNav]="isDesktopNav"
				[isNewBrowseMenuLinksEnabled]="isNewBrowseMenuLinksEnabled$ | async"
				globalNavMenuKeyboardNavigation
				[isKeyboardNavigationFocused]="isOpen && currentLevel === currentBrowseLevel.Aisles"
				[menuName]="currentBrowseLevel.Aisles"
				[parentMenuName]="currentBrowseLevel.Departments"
				(navigateToParentMenuEvent)="navigateToBrowseLevel($event)"
				(navigateToPriorMenuItemEvent)="navigateToPriorMenuItem($event)"
				(navigateToNextMenuItemEvent)="navigateToNextMenuItem($event)"
				(navigateToFirstMenuItemEvent)="navigateToFirstMenuItem($event)"
				(navigateToLastMenuItemEvent)="navigateToLastMenuItem($event)"
				[currentMenuItemIndex]="currentAisleIndex"
			></global-nav-browse-menu-items>
			<global-nav-browse-menu-items
				*ngIf="shelvesNavItems$ | async as shelvesNavItems"
				class="aisles"
				[showTitle]="false"
				[linkGroups]="shelvesNavItems"
				[hasIconRight]="false"
				[currentIndex]="currentShelveIndex"
				(itemClicked)="onShelveClick($event)"
				(shopAllClicked)="onShopAllClicked('Shelf')"
				[disallowSelectOnHover]="true"
				[hoverExperimentActive]="hoverExperimentActive"
				[isDesktopNav]="isDesktopNav"
				[isNewBrowseMenuLinksEnabled]="isNewBrowseMenuLinksEnabled$ | async"
				globalNavMenuKeyboardNavigation
				[isKeyboardNavigationFocused]="isOpen && currentLevel === currentBrowseLevel.Shelves"
				[menuName]="currentBrowseLevel.Shelves"
				[parentMenuName]="currentBrowseLevel.Aisles"
				(navigateToParentMenuEvent)="navigateToBrowseLevel($event)"
				(navigateToPriorMenuItemEvent)="navigateToPriorMenuItem($event)"
				(navigateToNextMenuItemEvent)="navigateToNextMenuItem($event)"
				(navigateToFirstMenuItemEvent)="navigateToFirstMenuItem($event)"
				(navigateToLastMenuItemEvent)="navigateToLastMenuItem($event)"
				[currentMenuItemIndex]="currentShelveIndex"
			></global-nav-browse-menu-items>
		</section>
	</div>
	<div class="green-area">
		<div class="promotionTiles">
			<ng-container *ngFor="let item of currentPromoTiles; let i = index; trackBy: trackByPromoTilesFn">
				<ng-container *ngIf="(isNewBrowseMenuLinksEnabled$ | async) === false; else newPromotionTileLink">
					<a
					*ngIf="showDynamicPromoTile(item)"
					role="navigation"
					maybeExternal
					[maybeExternalCurrentTab]="true"
					[attr.aria-label]="item.title"
					[href]="encodeUrlForPromoTile(item.navigationUrl, item.title, i + 1)"
					>
					<img
						[width]="dynamicPromoTileWidth"
						[src]="imageUrlDynamicPromoTile(item.title, item?.imageLink)"
						[alt]="item.title"
						loading="lazy"
						(click)="onPromoTileClicked(item.title, item.id, i + 1)"
						(error)="handleImageError($event)"
						[cdxTrackImpression]="this.promoImpressionArgs.get(item.title)"
					/>	
				</a>	
				</ng-container>
				
				<ng-template #newPromotionTileLink>
					<a
						*ngIf="showDynamicPromoTile(item)"
						role="navigation"
						maybeExternal
						[maybeExternalCurrentTab]="true"
						[attr.aria-label]="item.title"
						[href]="encodeUrlForPromoTile(item.navigationUrl, item.title, i + 1)"
					>
						<img
							[width]="dynamicPromoTileWidth"
							[src]="imageUrlDynamicPromoTile(item.title, item?.imageLink)"
							[alt]="item.title"
							loading="lazy"
							(click)="onPromoTileClicked(item.title, item.id, i + 1)"
							(error)="handleImageError($event)"
							[cdxTrackImpression]="this.promoImpressionArgs.get(item.title)"
						/>
					</a>
				</ng-template>
			</ng-container>

			@if(shouldShowFeatureRecipeStamp$ | async) {
				<global-nav-feature-recipe-stamp-component
					[aislesNavItems$]="aislesNavItems$" 
					[shelvesNavItems$]="shelvesNavItems$"
					[width]="dynamicPromoTileWidth"
				>				
				</global-nav-feature-recipe-stamp-component>
			}

			@if(shouldShowMegaMenuLongTile) {
				<global-nav-feature-mega-menu-long-tile
					[aislesNavItems$]="aislesNavItems$"
					[shelvesNavItems$]="shelvesNavItems$" 
					[width]="dynamicPromoTileWidth"
					(productClicked)="onProductClicked($event)"
					(click)="onPromoTileClicked(product?.name, 'long', 1)"
				>				
				</global-nav-feature-mega-menu-long-tile>
			}

			@if(shouldShowMegaMenuSmallTile) {
				<global-nav-feature-mega-menu-small-tile
					[aislesNavItems$]="aislesNavItems$"
					[shelvesNavItems$]="shelvesNavItems$" 
					[width]="dynamicPromoTileWidth"
					(click)="onPromoTileClicked(product?.name, 'short', 1)"
				>				
				</global-nav-feature-mega-menu-small-tile>
			}

		</div>
	</div>
	<ng-template #mobileNav>
		<div class="canDismiss" *ngIf="showBackButtonInAislesAndShelves">
			<button
				cdxButton
				fillStyle="primaryInverse"
				fullWidth="true"
				class="back-link"
				startSlotSize="small"
				[size]="'default'"
				[startSlotActive]="true"
				i18n="@@browseMenu-backToPreviousBrowse"
				(click)="handleBackToPreviousMenuClick()"
			>
				<cdx-svg-icon name="chevron-left" fill="green" size="small" start></cdx-svg-icon>
				Back
			</button>
			<cdx-svg-icon (click)="onClose()" name="cross" size="extra-small" fill="dark"></cdx-svg-icon>
		</div>
		<global-nav-browse-menu-items
			*ngIf="currentLevel === currentBrowseLevel.Aisles"
			class="aisles"
			[showTitle]="false"
			[linkGroups]="aislesNavItems$ | async"
			[hasIconRight]="true"
			[currentIndex]="currentAisleIndex"
			(itemClicked)="onAisleClick($event)"
			(shopAllClicked)="onShopAllClicked('Aisle')"
			[hoverExperimentActive]="hoverExperimentActive"
			[isDesktopNav]="isDesktopNav"
			[isNewBrowseMenuLinksEnabled]="isNewBrowseMenuLinksEnabled$ | async"
		></global-nav-browse-menu-items>

		<global-nav-browse-menu-items
			*ngIf="currentLevel === currentBrowseLevel.Shelves"
			class="aisles"
			[showTitle]="false"
			[linkGroups]="shelvesNavItems$ | async"
			[hasIconRight]="false"
			[currentIndex]="currentShelveIndex"
			(itemClicked)="onShelveClick($event)"
			(shopAllClicked)="onShopAllClicked('Shelf')"
			[hoverExperimentActive]="hoverExperimentActive"
			[isDesktopNav]="isDesktopNav"
			[isNewBrowseMenuLinksEnabled]="isNewBrowseMenuLinksEnabled$ | async"
		></global-nav-browse-menu-items>
	</ng-template>
</ng-container>
