import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TrackByFunction } from '@angular/core';
import {
	ButtonComponent,
	MaybeExternalLinkDirective,
	NavigationItemGroupWithAnalytics,
	NavigationItemWithAnalytics,
	SvgIconComponent,
	TrackEventDirective,
} from '@woolworthsnz/styleguide';
import { LinksComponent } from '../links/links.component';
import { GlobalNavSettingsService } from '../../services/global-nav-settings.service';
import { NavigationItemMap } from '../../models/browse-nav.model';
import { NgFor, NgIf, NgClass, AsyncPipe } from '@angular/common';
import { Subscription, timer } from 'rxjs';
@Component({
	selector: 'global-nav-browse-menu-items',
	template: `
		<div *ngFor="let linkGroup of linkGroups; trackBy: trackByGroupFn" class="linkGroup" role="navigation">
			<h4 *ngIf="showTitle && linkGroup.label">
				{{ linkGroup.label }}
				<button
					*ngIf="canDismiss"
					aria-label="Dismiss browse menu"
					type="button"
					cdxButton
					fillStyle="link"
					(click)="onClose()"
					i18n="@@CloseBrowseMenuButton"
				>
					<cdx-svg-icon name="cross" size="extra-small" fill="dark"></cdx-svg-icon>
				</button>
			</h4>
			<ul
				*ngIf="globalNavSettings$ | async as globalNavSettings"
				role="menu"
				class="menu"
				[attr.aria-label]="linkGroup.label"
			>
				<li
					*ngFor="let link of linkGroup.items; let i = index; trackBy: trackByLinkFn"
					role="none"
					class="menu-item"
					[ngClass]="{ 'menu-item--shopAllLink': link.type === 'shopAllLink' }"
				>
					<ng-container *ngIf="isNewBrowseMenuLinksEnabled; else originLinks">
						<a
							*ngIf="link.type === 'shopAllLink'; else newUsualLink"
							class="shopAllLink"
							maybeExternal
							[maybeExternalCurrentTab]="true"
							i18n="@@browseMenuItem-ShopAll{{link.label}}"
							[href]="link.url"
							[cdxTrackEvent]="trackingData(trackingName, link.label)"
							(click)="onShopAllClicked()"
							[ngClass]="{
								hoverExperimentActive: hoverExperimentActive
							}"
							role="menuitem"
						>
							Shop all {{ link.label }}
						</a>
						<ng-template #newUsualLink>
							<ng-container *ngIf="isNormalLink(); else specialLink">
								<a
									role="menuitem"
									class="link"
									maybeExternal
									[maybeExternalCurrentTab]="true"
									[href]="link.url"
									[cdxTrackEvent]="trackingData(trackingName || linkGroup.label, link.label)"
									(click)="onLinkClick($event, i, link)"
									(mouseover)="handleMouseover($event, i, link)"
									(mouseleave)="handleMouseleave()"
									[ngClass]="{
										hoverExperimentActive: hoverExperimentActive,
										selected: isSelected(link)
									}"
									[attr.aria-haspopup]="hasIconRight"
									[attr.aria-expanded]="hasIconRight && isSelected(link)"
								>
									<div
										class="linkLabel"
										[ngClass]="{
											withLinkIcon: link.iconUrl
										}"
									>
										<img
											class="linkIcon"
											*ngIf="link.iconUrl"
											[src]="link.iconUrl"
											loading="lazy"
											alt=""
											(error)="handleImageError($event)"
										/>
										<span>{{ link.label }}</span>
									</div>
									<cdx-svg-icon
										*ngIf="hasIconRight"
										name="chevron-right"
										fill="currentColor"
										size="extra-small"
									>
									</cdx-svg-icon>
								</a>
							</ng-container>
							<ng-template #specialLink>
								<a
									role="menuitem"
									class="link"
									[href]="link.url"
									[cdxTrackEvent]="trackingData(trackingName || linkGroup.label, link.label)"
									(click)="onLinkClick($event, i, link)"
									(mouseover)="handleMouseover($event, i, link)"
									(mouseleave)="handleMouseleave()"
									[ngClass]="{
										hoverExperimentActive: hoverExperimentActive,
										selected: isSelected(link)
									}"
									[attr.aria-haspopup]="hasIconRight"
									[attr.aria-expanded]="hasIconRight && isSelected(link)"
								>
									<div
										class="linkLabel"
										[ngClass]="{
											withLinkIcon: link.iconUrl
										}"
									>
										<img
											class="linkIcon"
											*ngIf="link.iconUrl"
											[src]="link.iconUrl"
											loading="lazy"
											alt=""
											(error)="handleImageError($event)"
										/>
										<span>{{ link.label }}</span>
									</div>
									<cdx-svg-icon
										*ngIf="hasIconRight"
										name="chevron-right"
										fill="currentColor"
										size="extra-small"
									>
									</cdx-svg-icon>
								</a>
							</ng-template>
						</ng-template>
					</ng-container>
					<ng-template #originLinks>
						<a
							*ngIf="link.type === 'shopAllLink'; else usualLink"
							class="shopAllLink"
							i18n="@@browseMenuItem-ShopAll{{link.label}}"
							[href]="link.url"
							[cdxTrackEvent]="trackingData(trackingName, link.label)"
							(click)="onShopAllClicked()"
							[ngClass]="{
								hoverExperimentActive: hoverExperimentActive
							}"
							role="menuitem"
						>
							Shop all {{ link.label }}
						</a>
						<ng-template #usualLink>
							<a
								role="menuitem"
								class="link"
								[href]="link.url"
								[cdxTrackEvent]="trackingData(trackingName || linkGroup.label, link.label)"
								(click)="onLinkClick($event, i, link)"
								(mouseover)="handleMouseover($event, i, link)"
								(mouseleave)="handleMouseleave()"
								[ngClass]="{
									hoverExperimentActive: hoverExperimentActive,
									selected: isSelected(link)
								}"
								[attr.aria-haspopup]="hasIconRight"
								[attr.aria-expanded]="hasIconRight && isSelected(link)"
							>
								<div
									class="linkLabel"
									[ngClass]="{
										withLinkIcon: link.iconUrl
									}"
								>
									<img
										class="linkIcon"
										*ngIf="link.iconUrl"
										[src]="link.iconUrl"
										loading="lazy"
										alt=""
										(error)="handleImageError($event)"
									/>
									<span>{{ link.label }}</span>
								</div>
								<cdx-svg-icon
									*ngIf="hasIconRight"
									name="chevron-right"
									fill="currentColor"
									size="extra-small"
								>
								</cdx-svg-icon>
							</a>
						</ng-template>
					</ng-template>
				</li>
			</ul>
		</div>
	`,
	styleUrls: ['./browse-menu-items.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		MaybeExternalLinkDirective,
		NgFor,
		NgIf,
		ButtonComponent,
		SvgIconComponent,
		TrackEventDirective,
		NgClass,
		AsyncPipe,
	],
})
export class BrowseMenuItemsComponent extends LinksComponent {
	@Input() showTitle = true;
	@Input() hasIconRight = false;
	@Input() canDismiss = false;
	@Input() disallowSelectOnHover = false;
	@Input() hoverExperimentActive = false;
	@Input() isDesktopNav = false;
	@Input() isNewBrowseMenuLinksEnabled = false;
	@Input() selectedItem: NavigationItemWithAnalytics | undefined = undefined;

	@Output() shopAllClicked = new EventEmitter<void>();
	@Output() itemClicked = new EventEmitter<NavigationItemMap>();
	@Output() mouseoverItem = new EventEmitter<NavigationItemMap>();
	@Output() closeButtonClicked = new EventEmitter<void>();

	private _currentIndex: number;
	private mouseEnterDebounce$?: Subscription;

	constructor(protected globalNavSettings: GlobalNavSettingsService) {
		super(globalNavSettings);
	}

	get currentIndex(): number {
		return this._currentIndex;
	}

	@Input() set currentIndex(value: number) {
		this._currentIndex = value;
	}

	isNormalLink(): boolean {
		return (this.hoverExperimentActive && this.isDesktopNav) || !this.hasIconRight;
	}

	onLinkClick(e: Event, index: number, item: NavigationItemWithAnalytics): void {
		if (!(this.hoverExperimentActive && this.isDesktopNav) && this.hasIconRight) {
			e.preventDefault();
		}
		this.currentIndex = index;
		this.itemClicked.emit({
			index: index,
			item: item,
		});
	}

	onShopAllClicked(): void {
		this.shopAllClicked.emit();
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	handleImageError($event: { target: { src: string } }): void {
		$event.target.src = '/images/global-nav/nav-experiment-v2/generic.png';
	}

	public onClose(): void {
		this.closeButtonClicked.emit();
	}

	trackByGroupFn: TrackByFunction<NavigationItemGroupWithAnalytics> = (_, linkGroup) => linkGroup.label;
	trackByLinkFn: TrackByFunction<NavigationItemWithAnalytics> = (_, link) => link.label;

	handleMouseover(e: Event, index: number, item: NavigationItemWithAnalytics): void {
		if (this.isDesktopNav && this.hoverExperimentActive) {
			if (this.mouseEnterDebounce$) {
				this.mouseEnterDebounce$.unsubscribe();
				this.mouseEnterDebounce$ = undefined;
			}
			this.mouseEnterDebounce$ = timer(250).subscribe(() => {
				this.currentIndex = index;
				this.mouseoverItem.emit({
					index: index,
					item: item,
				});
			});
		}
	}

	handleMouseleave(): void {
		this.mouseEnterDebounce$?.unsubscribe();
	}

	isSelected({ id, url }: NavigationItemWithAnalytics): boolean {
		return id === this.selectedItem?.id && url === this.selectedItem?.url;
	}
}
