import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LIQUOR_LICENSES_PATH, PRIVACY_POLICY_PATH, TERM_CONDITIONS_PATH } from '@woolworthsnz/shop';
import { AppSettingsService, MaybeExternalLinkDirective } from '@woolworthsnz/styleguide';
import { NavigationItem } from '@woolworthsnz/trader-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalNavSettingsService } from '../../services/global-nav-settings.service';
import { NgFor, AsyncPipe } from '@angular/common';

@Component({
	selector: 'global-nav-legal-bar',
	templateUrl: './legal-bar.component.html',
	styleUrls: ['./legal-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgFor, MaybeExternalLinkDirective, AsyncPipe]
})
export class LegalBarComponent implements OnInit {
	externalLinksInCurrentTab$: Observable<boolean>;
	currentYear = new Date().getFullYear();
	links: NavigationItem[] = [];

	liquorLicensesPath = `/${LIQUOR_LICENSES_PATH}`;
	privacyPolicyPath = `/${PRIVACY_POLICY_PATH}`;
	termConditionsPath = `/${TERM_CONDITIONS_PATH}`;

	constructor(
		private appSettingsService: AppSettingsService,
		private globalNavSettings: GlobalNavSettingsService
	) { }

	public ngOnInit(): void {
		this.links = this.appSettingsService.getSetting('navs')?.legalBarNav;
		this.externalLinksInCurrentTab$ = this.globalNavSettings
			.getSettings()
			.pipe(map((settings) => settings.externalLinksInCurrentTab));
	}
}
