import { isPlatformServer, NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Optional,
	Output,
	PLATFORM_ID,
	QueryList,
	TrackByFunction,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { ShellService } from '@woolworthsnz/shop';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import {
	AppSettingsService,
	BreakPointService,
	CutoutModalTargetDirective,
	Device,
	isLinkAbsolute,
	ModalOverlayRef,
	NavigationMenuItemWithAnalytics,
	NotificationType,
	TrackingEvent,
	TRADER_BASE_URL,
	ButtonComponent,
	PopupComponent,
	PopupTargetDirective,
	SvgIconComponent,
	TrackEventDirective,
} from '@woolworthsnz/styleguide';
import { NavigationMenuItem } from '@woolworthsnz/trader-api';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalNavSettingsService } from '../../services/global-nav-settings.service';
import { NavItemsService } from '../../services/nav-items.service';
import { FAVOURITES_AND_LISTS } from './main-nav.constants';
import { BrowseMenuComponent } from '../browse-menu/browse-menu.component';
import { tealiumUtagBrowseMenu } from '../../constants/tealium-events.constants';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { LinksComponent } from '../links/links.component';

@Component({
	selector: 'global-nav-main-nav, [globalEmbeddedNav]',
	templateUrl: './main-nav.component.html',
	styleUrls: ['./main-nav.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		NgIf,
		NgFor,
		PopupComponent,
		ButtonComponent,
		CutoutModalTargetDirective,
		PopupTargetDirective,
		TrackEventDirective,
		SvgIconComponent,
		BrowseMenuComponent,
		LinksComponent,
		NgClass,
		RouterLinkActive,
		RouterLink,
		AsyncPipe,
	],
})
export class MainNavComponent implements OnInit, AfterViewInit {
	@Input() isNavExperimentV1Desktop = false;
	@ViewChild('navList') navList: ElementRef<HTMLUListElement>;
	@ViewChildren('navElements') navElements: QueryList<ElementRef>;
	@ViewChild('browseMenu') browseMenu: BrowseMenuComponent;

	@ViewChildren(CutoutModalTargetDirective)
	public modalTarget: QueryList<CutoutModalTargetDirective>;

	@Output() menuOpen = new EventEmitter<boolean>();

	modal: ModalOverlayRef;
	navItems$: Observable<NavigationMenuItemWithAnalytics[]>;
	isLargeDevice$: Observable<boolean>;
	isEmbedded$ = new BehaviorSubject(false);
	favouritesAndLists = FAVOURITES_AND_LISTS;
	disableNewNavIndication$: Observable<boolean>;
	isMenuOpen = false;
	public popupOpenMap = new Map<number, boolean>();
	public browseNavItem$: Observable<NavigationMenuItemWithAnalytics | undefined>;
	public filteredNavItemsShownInBrowse$: Observable<NavigationMenuItemWithAnalytics[]>;

	private stickyHeaderObserver: IntersectionObserver | undefined;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	private _stickyTarget: any;
	private trackingEvent = TrackingEvent;
	private notificationType = NotificationType;

	constructor(
		private hostElement: ElementRef,
		private shellService: ShellService,
		private breakPointService: BreakPointService,
		@Inject(PLATFORM_ID) private platformId: Object,
		public appSettingsService: AppSettingsService,
		private navItemsService: NavItemsService,
		@Optional() @Inject(TRADER_BASE_URL) public baseDomain: string,
		private globalNavSettings: GlobalNavSettingsService,
		private tealiumUtagService: TealiumUtagService
	) {}

	@Input() set stickyTarget(el: ElementRef) {
		if (el) {
			this._stickyTarget = el.nativeElement;
			this.stickyHeaderObserver?.observe(el.nativeElement);
		}
	}

	ngOnInit(): void {
		this.isLargeDevice$ = this.breakPointService.device$.pipe(
			map((device) => device === Device.LARGE || device === Device.XLARGE)
		);

		this.navItems$ = this.navItemsService.navItems$.pipe(
			map(this.filterForNavExperimentV1.bind(this))
		);

		this.browseNavItem$ = this.navItemsService.browseNavItem$;
		this.filteredNavItemsShownInBrowse$ = this.navItemsService.filteredNavItemsShownInBrowse$;

		this.isEmbedded$.next(this.appSettingsService.getSetting('isEmbeddedInApp'));

		this.disableNewNavIndication$ = this.globalNavSettings
			.getSettings()
			.pipe(map((settings) => settings.disableNewNavItemIndication));
	}

	ngAfterViewInit(): void {
		if (isPlatformServer(this.platformId)) {
			return;
		}

		this.stickyHeaderObserver = new IntersectionObserver(
			([e]) => {
				e.target.toggleAttribute('data-stuckToTop', e.intersectionRatio < 1);
				this.hostElement?.nativeElement.toggleAttribute('flat', e.intersectionRatio < 1);
			},
			{
				threshold: [1],
			}
		);

		if (this._stickyTarget) {
			this.stickyHeaderObserver.observe(this._stickyTarget);
		}

		this.scrollActiveIntoView();
	}

	scrollActiveIntoView(): void {
		// Have to put this in a setTimeout to give the routerLinkActive directive time to add the class
		setTimeout(() => {
			const activeChild = this.navElements
				.toArray()
				.find((el) => el.nativeElement.firstChild.classList.contains('active'));

			if (activeChild?.nativeElement?.offsetLeft) {
				this.navList.nativeElement.scrollLeft = activeChild.nativeElement.offsetLeft;
			}
		});
	}

	handleTrackEvent(item: NavigationMenuItem): {
		event: TrackingEvent;
		type: NotificationType;
		name: string | undefined;
	} | null {
		// If it has sub-menu items, don't track any
		if (item.navigationItems?.length) {
			return null;
		}

		return {
			event: this.trackingEvent.NotificationEvent,
			type: this.notificationType.Global,
			name: item.label,
		};
	}

	handleOpenChange(event: boolean, index: number): void {
		if (this.browseMenu) {
			this.browseMenu.resetData();
			this.tealiumUtagService.link(
				{
					...tealiumUtagBrowseMenu,
					content_name: 'Browse button',
				},
				true
			);
		}

		// Keep track of all the popups and emit if any are open
		// Mainly to stop race conditions when going immediately from one popup to another
		this.popupOpenMap.set(index, event);
		const anyOpen = [...this.popupOpenMap.values()].some((val) => val);
		this.isMenuOpen = anyOpen;
		this.menuOpen.emit(anyOpen);
	}

	trackByFn: TrackByFunction<NavigationMenuItemWithAnalytics> = (_: any, item: NavigationMenuItemWithAnalytics) =>
		item.label;

	isURLAbsolute(url: string): boolean {
		return isLinkAbsolute(url);
	}

	isBrowseNavItem(item: NavigationMenuItemWithAnalytics): boolean {
		return item.label?.toLowerCase() === 'browse';
	}

	onClickLink(event: Event, item: NavigationMenuItemWithAnalytics): void {
		if (!this.appSettingsService.getSetting('isEmbeddedInApp') && this.isBrowseNavItem(item)) {
			event.preventDefault();
			this.tealiumUtagService.link(
				{
					...tealiumUtagBrowseMenu,
					content_name: 'Browse button',
				},
				true
			);
			this.shellService.toggleBrowseNav('show');
		}
	}

	private filterForNavExperimentV1(navItems: NavigationMenuItemWithAnalytics[]): NavigationMenuItemWithAnalytics[] {
		if (!this.isNavExperimentV1Desktop) {
			return navItems;
		}
		const allowedItems = ['Browse', 'Specials', 'Favourites & lists'];
		return navItems.filter((item) => item.label && allowedItems.includes(item.label));
	}
}
