import { transition, trigger } from '@angular/animations';
import { isPlatformBrowser, isPlatformServer, NgIf, NgClass, AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
	QueryList,
	TrackByFunction,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { ShellService, ShellState } from '@woolworthsnz/shop';
import {
	BreakPointService,
	ButtonComponent,
	Device,
	fadeInSteps,
	fadeOutSteps,
	isLinkAbsolute,
	NavigationMenuItemWithAnalytics,
	ScrollBlockDirective,
} from '@woolworthsnz/styleguide';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { NavItemsService } from '../../services/nav-items.service';
import { BrowseMenuComponent } from '../browse-menu/browse-menu.component';

@Component({
	selector: 'global-nav-browse-nav',
	templateUrl: './browse-nav.component.html',
	styleUrls: ['./browse-nav.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('maskFade', [transition(':enter', [...fadeInSteps]), transition(':leave', [...fadeOutSteps])]),
	],
	standalone: true,
	imports: [NgIf, NgClass, BrowseMenuComponent, ScrollBlockDirective, AsyncPipe],
})
export class BrowseNavComponent implements OnInit, OnDestroy {
	@ViewChild('nav') nav: ElementRef<HTMLElement>;
	@ViewChild('browseMenu') browseMenu: BrowseMenuComponent;
	@ViewChildren('navButton') navList: QueryList<ButtonComponent>;

	shellState: ShellState;
	public filteredNavItemsShownInBrowse$: Observable<NavigationMenuItemWithAnalytics[]>;
	public browseNavItem$: Observable<NavigationMenuItemWithAnalytics | undefined>;

	private navAnimationDuration = 600;
	private destroyed$: Subject<boolean> = new Subject();
	private navAnimationTimeout: ReturnType<typeof setTimeout>;

	constructor(
		private cdr: ChangeDetectorRef,
		private shellService: ShellService,
		private breakpointService: BreakPointService,
		private navItemsService: NavItemsService,
		@Inject(PLATFORM_ID) private platformId: Object
	) {}

	public ngOnInit(): void {
		if (isPlatformServer(this.platformId)) {
			this.navAnimationDuration = 0;
		}

		this.browseNavItem$ = this.navItemsService.browseNavItem$;
		this.filteredNavItemsShownInBrowse$ = this.navItemsService.filteredNavItemsShownInBrowse$;

		if (!this.shellService.hasInitialised) {
			this.shellService.getApplicationShell().pipe(takeUntil(this.destroyed$)).subscribe();
		}

		this.shellService.state$.pipe(takeUntil(this.destroyed$)).subscribe((state) => {
			this.shellState = state;
		});

		if (isPlatformBrowser(this.platformId)) {
			this.breakpointService.device$
				.pipe(
					takeUntil(this.destroyed$),
					filter(
						(device) => device === Device.DESKTOP || device === Device.LARGE || device === Device.XLARGE
					),
					tap(() => this.hideBrowseNav())
				)
				.subscribe();
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
    	this.destroyed$.complete();
		if (this.navAnimationTimeout) {
			clearTimeout(this.navAnimationTimeout);
		}
	}

	trackByFn: TrackByFunction<NavigationMenuItemWithAnalytics> = (_: any, item: NavigationMenuItemWithAnalytics) =>
		item.label;

	isURLAbsolute(url: string): boolean {
		return isLinkAbsolute(url);
	}

	hideBrowseNav(): void {
		this.shellService.toggleBrowseNav('hide');

		if (this.navAnimationTimeout) {
			clearTimeout(this.navAnimationTimeout);
		}

		this.navAnimationTimeout = setTimeout(() => {
			if (this.nav) {
				this.nav.nativeElement.scrollTop = 0;
			}
			this.browseMenu.resetData();
			this.cdr.markForCheck();
		}, this.navAnimationDuration);
	}

	public handleNavItemClicked(): void {
		this.hideBrowseNav();
	}

	public onClose(): void {
		this.hideBrowseNav();
	}
}
