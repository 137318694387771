import {
	NavigationMenuItemWithAnalytics,
	NavigationItemGroupWithAnalytics,
	isLinkAbsolute,
} from '@woolworthsnz/styleguide';
import { BrowseMenuPromoTile, NavigationMenuItem } from '@woolworthsnz/trader-api';
import { Observable, map } from 'rxjs';

export function sortMainNavItemsById(mainNavs: NavigationMenuItem[]): NavigationMenuItem[] {
	const mainNavsCopy: NavigationMenuItem[] = JSON.parse(JSON.stringify(mainNavs));
	mainNavsCopy
		.filter((nav) => nav.label?.toLowerCase() !== 'browse')
		.forEach((nav) => {
			nav.navigationItems?.forEach((navItem) => {
				navItem.items?.sort((a, b) => (a.id && b.id ? a.id - b.id : 0));
			});
		});

	return mainNavsCopy;
}

export function addDomain(mainNavs: NavigationMenuItem[], baseDomain?: string): NavigationMenuItem[] {
	if (!baseDomain) {
		return mainNavs;
	}
	const mainNavsCopy: NavigationMenuItem[] = JSON.parse(JSON.stringify(mainNavs));
	return mainNavsCopy.map((nav) => ({
		...nav,
		url: `${baseDomain}${nav.url}`,
		navigationItems: nav.navigationItems?.map((navItemGroup) => ({
			...navItemGroup,
			items: navItemGroup.items?.map((item) => ({
				...item,
				url: `${baseDomain}${item.url}`,
			})),
		})),
	}));
}

export function addAnalyticsNameToMainNav(mainNavs: NavigationMenuItem[]): NavigationMenuItemWithAnalytics[] {
	const mainNavsCopy: NavigationMenuItem[] = JSON.parse(JSON.stringify(mainNavs));
	const browseEntry = mainNavsCopy.find((navItem) => navItem.label === 'Browse' && navItem.navigationItems);
	if (!browseEntry || !browseEntry?.navigationItems) {
		return mainNavsCopy;
	}
	browseEntry.navigationItems = browseEntry.navigationItems.map((navItems) => {
		navItems.items =
			navItems.items?.map((item) => ({
				...item,
				analyticsName: item.url?.replace('/shop/browse/', ''),
			})) ?? [];

		return navItems;
	});
	return mainNavsCopy;
}

function getLabelBySessionGroup(sessionGroups: number[], labels: { [x: string]: string }): string {
	let newLabel = '';
	for (const sessionGroup of sessionGroups) {
		newLabel = labels[sessionGroup.toString()];
		if (newLabel) {
			break;
		}
	}

	return newLabel;
}

function getNewLabel(label: string, sessionGroups?: number[], labels?: { [x: string]: string }): string {
	if (!labels || !sessionGroups?.length) {
		return label;
	}

	const newLabel = getLabelBySessionGroup(sessionGroups, labels);

	// Special case when label is valid e.g. shop but there is no newLabel matched. Still need to show the original label
	if (label && !newLabel) {
		return label;
	}

	return newLabel;
}

export function filterNavsBySessionGroup(
	mainNavs?: NavigationMenuItem[],
	sessionGroups?: number[],
	isExpress?: boolean
): NavigationMenuItem[] {
	if (!mainNavs) {
		return [];
	}

	const filteredNavs: NavigationMenuItem[] = [];

	mainNavs.forEach((nav) => {
		// Suppress the Express label when isExpress is false
		if (!isExpress && nav.label?.toLowerCase() === 'express') {
			return;
		}

		const newLabel = getNewLabel(nav.label ?? '', sessionGroups, nav.labels);

		// Only add to returned list if the label is not empty
		if (newLabel) {
			nav.label = newLabel;
			filteredNavs.push(nav);
		}
	});

	return filteredNavs;
}

export function normaliseNavLabel(label: string): string {
	if (!label) {
		return 'generic';
	}
	const regex = /[^a-zA-Z0-9]/g;
	return label.toLowerCase().replace(regex, '-');
}

export function replaceBrowseSubHeading(item: NavigationItemGroupWithAnalytics): NavigationItemGroupWithAnalytics {
	return item.label === 'Department' ? { ...item, label: 'Browse by aisle' } : item;
}

export function addDomainAndSortPromoTiles(
	promoTiles: BrowseMenuPromoTile[],
	baseDomain?: string
): BrowseMenuPromoTile[] {
	let updatedPromoTiles = [...promoTiles];
	if (baseDomain) {
		updatedPromoTiles = promoTiles.map((item) => ({
			...item,
			...(item.navigationUrl && {
				navigationUrl: isLinkAbsolute(item.navigationUrl)
					? item.navigationUrl
					: `${baseDomain}${item.navigationUrl}`,
			}),
			...(item.imageLink && {
				imageLink: isLinkAbsolute(item.imageLink) ? item.imageLink : `${baseDomain}${item.imageLink}`,
			}),
		}));
	}
	return updatedPromoTiles.sort((a, b) => (a.order && b.order ? a.order - b.order : 0));
}

// ticket number CCFRM-3710: will revert type back after backend api updated
export function treatDynamicPromoTiles(
	navItems: NavigationItemGroupWithAnalytics[] | undefined,
	baseDomain?: string
): NavigationItemGroupWithAnalytics[] | undefined {
	if (!navItems) {
		return undefined;
	}

	return navItems.map((navItem: NavigationItemGroupWithAnalytics) => {
		const newItem = { ...navItem };
		if (newItem.promoTiles) {
			newItem.promoTiles = addDomainAndSortPromoTiles(newItem.promoTiles, baseDomain);
		}
		newItem.items = newItem.items?.map((item) => {
			const updatedItem = {
				...item,
				...(item.promoTiles && { promoTiles: addDomainAndSortPromoTiles(item.promoTiles, baseDomain) }),
			};

			updatedItem.dasFacets = item.dasFacets?.map((dasFacet) => ({
				...dasFacet,
				...(dasFacet.promoTiles && { promoTiles: addDomainAndSortPromoTiles(dasFacet.promoTiles, baseDomain) }),
			}));
			return updatedItem;
		});
		return newItem;
	});
}

export function addNavLinkIcons(
	item: NavigationItemGroupWithAnalytics,
	fileExtension: 'svg' | 'png' = 'png'
): NavigationItemGroupWithAnalytics {
	const items = item.items?.map((navItem) => ({
		...navItem,
		...(navItem.label && {
			iconUrl: `/images/global-nav/nav-experiment-v2/${normaliseNavLabel(navItem.label || '')}.${fileExtension}`,
		}),
	}));

	return { ...item, ...(!!items && { items }) };
}

export function getBrowseNavItems(
	navItems$: Observable<NavigationMenuItemWithAnalytics[]>,
	baseDomain?: string
): Observable<NavigationMenuItemWithAnalytics | undefined> {
	return navItems$.pipe(
		map((navItems) => navItems.find((item) => item.label?.toLowerCase() === 'browse')),
		map((browseNavItem) => {
			const navigationItems = Array.isArray(browseNavItem?.navigationItems)
				? browseNavItem?.navigationItems
						.map(replaceBrowseSubHeading)
						.map((item) => addNavLinkIcons(item, 'png'))
				: browseNavItem?.navigationItems;

			return { ...browseNavItem, navigationItems: treatDynamicPromoTiles(navigationItems, baseDomain) };
		})
	);
}

export function getFilteredNavItemsForBrowseMenuExperiment(
	navItems$: Observable<NavigationMenuItemWithAnalytics[]>
): Observable<NavigationMenuItemWithAnalytics[]> {
	return navItems$.pipe(
		map((navItems: NavigationMenuItemWithAnalytics[]) =>
			navItems
				.filter((item) => item.label && ['Recipes', 'Favourites & lists'].includes(item.label))
				.reduce(
					(
						acc: NavigationMenuItemWithAnalytics[],
						item: NavigationMenuItemWithAnalytics
					): NavigationMenuItemWithAnalytics[] => {
						const navItemPastOrder =
							item.label === 'Favourites & lists'
								? item?.navigationItems &&
								  item?.navigationItems[0]?.items?.find((subItem) => subItem.label === 'Past orders')
								: undefined;
						if (navItemPastOrder) {
							return [...acc, navItemPastOrder];
						}
						return [...acc, item];
					},
					[]
				)
				.map((item) => ({ ...item, icon: normaliseNavLabel(item.label || '') }))
		)
	);
}
