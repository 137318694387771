<div class="changeOrderBar">
	<strong i18n="@@changeOrderBar-editingOrderTitle">You're editing {{ orderDisplay$ | async }}.</strong>
	<span i18n="@@changeOrderBar-editableUntilTeamStartsPicking">
		You can edit your order until our team starts picking it.
	</span>
	<ng-container *ngIf="isMobileDevice === false && enabledChangedPriceTutorial === true; then renderPopupContent; else noPopup"></ng-container>
	<ng-template #renderPopupContent>
		<cdx-popup 
			popupActiveClass="popupOpen"
			customClass="changePriceTutorial" 
			hasBackdrop="true" 
			popupId="priceChangePopupStepTwo"
			[shouldListenToClickAway]="false"
			[hasCloseBtn]="true"
		>
			<button 
				type="button"
				cdxButton 
				fillStyle="link" 
				(click)="showCancelChangeOrderModal()" 
				i18n="@@changeOrderBar-cancelChangeOrderLink" 
				aria-label="Cancel edits"
				class="cancelEditsButtonLink"
				>Cancel edits
			</button>
			<button
				cdxPopupTarget
				[ignoreClickEvent]="true"
				aria-label="hint icon button"
				type="button"
				cdxButton
				fillStyle="link"
				i18n="@@open-changed-price-tutorial"
				(click)="handleHintIconClick()"
			>
				<cdx-svg-icon name="hint" size="small" fill="white"></cdx-svg-icon>
			</button>
			<ng-container cardContent>
				<h5>Revert to your original order</h5>
				<p>Changed your mind? Click ‘Cancel all edits’ to revert to your original order.</p>
				<div class="popupButtons">
					<span>2/2</span>
					<button cdxButton fillStyle="link" i18n="@@tutorialPopupStepTwo-backBtn" (click)="handlePriceChangeTutorialBack()">Back</button>
				</div>
			</ng-container>
		</cdx-popup>
	</ng-template>
	<ng-template #noPopup>
		<button 
			type="button"
			cdxButton 
			fillStyle="link" 
			(click)="showCancelChangeOrderModal()" 
			i18n="@@changeOrderBar-cancelChangeOrderLink" 
			aria-label="Cancel edits"
			class="cancelEditsButtonLink"
			>Cancel edits
		</button>
	</ng-template>
</div>
