import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Optional } from '@angular/core';
import {
	ButtonComponent,
	BreakPointService,
	Device,
	fadeSlideUp,
	lozengeZoomInOut,
	MaybeExternalLinkDirective,
	NotificationType,
	SvgIconComponent,
	TrackEventDirective,
	TrackingEvent,
	TRADER_BASE_URL,
} from '@woolworthsnz/styleguide';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalNavSettingsService } from '../../services/global-nav-settings.service';
import { NgFor, AsyncPipe } from '@angular/common';
@Component({
	selector: 'global-nav-basket-totals',
	templateUrl: './basket-totals.component.html',
	styleUrls: ['./basket-totals.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [...lozengeZoomInOut, ...fadeSlideUp],
	standalone: true,
	imports: [MaybeExternalLinkDirective, ButtonComponent, TrackEventDirective, NgFor, SvgIconComponent, AsyncPipe],
})
export class BasketTotalsComponent implements OnInit {
	@Input() darkTheme: boolean;
	@Input() amountToPay = '$0.00';
	@Input() totalItems = 0;
	@Input() savings = '$0.00';
	@Input() trolleyLink: string;

	viewportSmall$: Observable<boolean>;
	trackingEvent = TrackingEvent;
	notificationType = NotificationType;
	externalLinksInCurrentTab$: Observable<boolean>;

	constructor(
		private breakpointService: BreakPointService,
		private globalNavSettings: GlobalNavSettingsService,
		@Optional() @Inject(TRADER_BASE_URL) private traderBaseUrl?: string
	) {}

	get trolleyLinkUrl(): string {
		return `${this.traderBaseUrl ?? ''}/${this.trolleyLink}`;
	}

	get hasSavings(): boolean | string {
		return this.savings && this.savings !== '$0.00';
	}

	get trolleyIcon(): string {
		return this.totalItems ? 'trolley-filled' : 'trolley';
	}

	get ariaLabel(): string {
		return `${this.totalItems} item${this.totalItems !== 1 ? 's' : ''} in basket`;
	}

	get checkoutAriaLabel(): string {
		return `Review order or checkout ${this.amountToPay}`;
	}

	get iconColor(): 'light' | 'green' {
		return this.darkTheme ? 'light' : 'green';
	}

	get iconColorSmall(): 'light' | 'dark' {
		return this.darkTheme ? 'light' : 'dark';
	}

	get buttonFillStyle(): 'primaryFlatInverse' | 'tertiaryFlat' {
		return this.darkTheme ? 'primaryFlatInverse' : 'tertiaryFlat';
	}

	get globalNavButtonFillStyle(): 'primaryInverse' | 'primary' {
		return this.darkTheme ? 'primaryInverse' : 'primary';
	}

	get globalNavIconColor(): 'green' | 'light' {
		return this.darkTheme ? 'green' : 'light';
	}

	ngOnInit(): void {
		this.viewportSmall$ = this.breakpointService.device$.pipe(
			map((d) => d === Device.MOBILE || d === Device.TABLETPORTRAIT || d === Device.TABLET)
		);

		this.externalLinksInCurrentTab$ = this.globalNavSettings
			.getSettings()
			.pipe(map((settings) => settings.externalLinksInCurrentTab));
	}
}
