<nav
	class="olynav-drawer nav-drawer-global"
	[ngClass]="{
		'olynav-shown': shellState?.showBrowseNav
	}"
	#nav
>
	<div
		*ngIf="browseNavItem$ | async; let browseNavItem"
		class="olynav-level olynav-level--visible olynav-level--full-height olynav-level--initially-hidden browse-menu"
	>
		<global-nav-browse-menu
			#browseMenu
			[isDesktopNav]="false"
			[showTitle]="true"
			[browseNavItems]="browseNavItem.navigationItems"
			[otherNavItems]="filteredNavItemsShownInBrowse$ | async"
			[trackingName]="browseNavItem.label"
			(navItemClicked)="handleNavItemClicked()"
			(closeButtonClicked)="onClose()"
		></global-nav-browse-menu>
	</div>
</nav>

<div cdxScrollBlock [@maskFade] class="olynav-mask" *ngIf="shellState?.showBrowseNav" (click)="hideBrowseNav()"></div>
