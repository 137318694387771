import { AsyncPipe, CurrencyPipe, NgIf, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import {
	FeatureService,
	kebabCase,
	CapitalizeSpecificWordsPipe,
	SrPunctuationPipe,
	DeferLoadDirective,
	sentenceCase,
	JoinPipe,
	ButtonComponent,
} from '@woolworthsnz/styleguide';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { ContextResponse, ProductResponse, ProductDetailsResponse } from '@woolworthsnz/trader-api';
import { ProductService } from '../../services/product-service';

@Component({
	selector: 'global-nav-feature-product-stamp',
	templateUrl: './feature-product-stamp.component.html',
	styleUrls: ['./feature-product-stamp.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		AsyncPipe,
		CurrencyPipe,
		NgIf,
		RouterLink,
		TitleCasePipe,
		CapitalizeSpecificWordsPipe,
		SrPunctuationPipe,
		DeferLoadDirective,
		JoinPipe,
		ButtonComponent,
	],
})
export class FeatureProductStampComponent implements OnInit, OnDestroy {
	@Input()
	public sku = '';
	public isFeatureProductStampEnabled = false;
	public isVisible$ = new BehaviorSubject<boolean>(false);
	private destroyed$: Subject<boolean> = new Subject();

	constructor(private featureService: FeatureService, public productService: ProductService) {}

	get product(): ProductDetailsResponse {
		return this.productService.state.product;
	}

	get cupPrice(): string | false {
		return this.productService.cupPrice;
	}

	get isKgItem(): boolean {
		return this.productService.unit === ProductResponse.UnitEnum.Kg;
	}

	get supportsBothEachAndKgPricing(): boolean | undefined {
		return this.productService.supportsBothEachAndKgPricing;
	}

	get isEachKgMeatProduct(): boolean | undefined {
		return this.supportsBothEachAndKgPricing;
	}

	get productTitle(): string | undefined {
		return this.isEachKgMeatProduct && this.product?.size?.volumeSize
			? `${this.product?.name} ${this.product?.size?.volumeSize}`
			: this.product?.name;
	}

	get unit(): ProductResponse.UnitEnum | undefined {
		if (this.productService.supportsBothEachAndKgPricing) {
			return this.productService.selectedPurchasingUnit;
		}

		return this.productService.unit;
	}

	get nameInKebabCase(): string {
		return kebabCase(this.product?.name || '');
	}

	get src(): string {
		return (this.product?.images || [])[0].small || '';
	}

	get volumeSize(): string | false | undefined {
		return (
			this.product?.size?.volumeSize &&
			!this.product.supportsBothEachAndKgPricing &&
			sentenceCase(this.product?.size?.volumeSize)
		);
	}

	get packageType(): string | false | undefined {
		return (
			this.product?.size?.packageType &&
			!this.product.supportsBothEachAndKgPricing &&
			sentenceCase(this.product?.size?.packageType)
		);
	}

	get ariaLabels(): string[] {
		const labels: string[] = [];
		if (this.packageType) {
			labels.push(`Package type ${this.packageType}.`);
		}
		if (this.volumeSize && !this.isKgItem) {
			labels.push(`Volume size ${this.volumeSize}.`);
		}
		if (this.cupPrice) {
			labels.push(`Cup price ${this.cupPrice}.`);
		}
		return labels;
	}

	ngOnInit(): void {
		this.featureService
			.isEnabled(ContextResponse.EnabledFeaturesEnum.EnableFeatureProductStamp)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((isFeatureProductStampEnabled) => {
				this.isFeatureProductStampEnabled = isFeatureProductStampEnabled;
				if (isFeatureProductStampEnabled) {
					this.loadProductData();
				}
			});
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
		this.isVisible$.complete();
	}

	loadProductData(): void {
		this.productService
			.getProductDetail(this.sku)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((response: ProductDetailsResponse) => {
				this.productService.setState({ product: response });
				this.isVisible$.next(true);
			});
	}

	getImageUrl(type: string): string {
		return `/images/product_${type}.png`;
	}
}
