import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ApiService, AppSettingsService, LoggingService, StatefulService } from '@woolworthsnz/styleguide';
import { ProductDetailsResponse, ProductResponse, SizeResponse } from '@woolworthsnz/trader-api';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export class ProductState {
	product: ProductDetailsResponse;
}

@Injectable({
	providedIn: 'root',
})
export class ProductService extends StatefulService<ProductState> {
	constructor(
		public apiService: ApiService,
		private appSettingsService: AppSettingsService,
		private loggingService: LoggingService,
		private currencyPipe: CurrencyPipe
	) {
		super(new ProductState());
	}

	get product(): ProductDetailsResponse | null {
		return this.state.product;
	}

	get cupPrice(): string | false {
		return this.size && this.size.cupMeasure && this.size.cupPrice
			? `${this.getPriceAsCurrency(this.size.cupPrice)} / ${this.size.cupMeasure}`
			: false;
	}

	get size(): SizeResponse | undefined {
		return this.product?.size;
	}

	get unit(): 'Each' | 'Kg' | undefined {
		return this.product?.unit;
	}

	get supportsBothEachAndKgPricing(): boolean | undefined {
		return (this.product as ProductResponse)?.supportsBothEachAndKgPricing;
	}

	get selectedPurchasingUnit(): ProductResponse.UnitEnum | undefined {
		return this.product?.selectedPurchasingUnit;
	}

	get productIsInStock(): boolean {
		return this.product?.availabilityStatus?.toString().toLowerCase() !== 'out of stock';
	}

	getPriceAsCurrency(price: string | number): string {
		return this.currencyPipe.transform(price, 'NZD', 'symbol-narrow', '1.2-2') || '';
	}

	getProductDetail(sku: string): Observable<ProductDetailsResponse> {
		return this.apiService.get(`${this.appSettingsService.getEndpoint('productDetails')}/${sku}`, {}).pipe(
			catchError((error) => {
				this.loggingService.error(error);
				return of(error);
			}),
			tap(() => {
				this.setState({});
			})
		);
	}
}
