import { FilterCriteria, ShelfResponse } from '@woolworthsnz/trader-api';
import { kebabCase, NavigationItemWithAnalytics, replaceSpecialCharacters } from '@woolworthsnz/styleguide';

export function generateAislesNavItems(
	dasFacets: Array<FilterCriteria>,
	departmentUrl: string,
	departmentName: string
): NavigationItemWithAnalytics[] {
	const departmentLink = [
		{
			label: departmentName,
			url: departmentUrl,
			type: 'shopAllLink',
		},
	];

	const aisleLinks = dasFacets.map((dasFacet) => {
		const dasFilterName = kebabCase(replaceSpecialCharacters(dasFacet.name || ''));
		const url = departmentUrl.includes('/shop/browse/')
			? `${departmentUrl}/${dasFilterName}`
			: `/shop/browse/${departmentUrl}/${dasFilterName}`;

		return {
			id: dasFacet.value,
			label: dasFacet.name,
			analyticsName: url.slice(url.lastIndexOf('/') + 1),
			...(departmentUrl && { url: url }),
			shelfResponses: dasFacet.shelfResponses,
		};
	});

	return [...departmentLink, ...aisleLinks];
}

export function generateShelvesNavItems(
	response: ShelfResponse[],
	urlPrefix: string,
	aisleName: string
): NavigationItemWithAnalytics[] {
	const aisleLink = [
		{
			label: aisleName,
			url: urlPrefix,
			type: 'shopAllLink',
		},
	];

	const shelfLinks = response?.map(
		(shelf: ShelfResponse) =>
			({
				id: shelf?.id,
				label: shelf?.label,
				url: `${urlPrefix}/${shelf.url}`,
				analyticsName: shelf.url,
			} as NavigationItemWithAnalytics)
	);

	return [...aisleLink, ...shelfLinks];
}
