import {
	Directive,
	ElementRef,
	HostListener,
	Input,
	Output,
	OnInit,
	OnChanges,
	AfterViewInit,
	EventEmitter,
	SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { KeyValue } from './keyboard';
import { hasSubmenu, setElementTabbable, focusOnCurrentMenuItem } from './menu-keyboard-navigation.helper';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const SUPPORT_KEYS: ReadonlyArray<KeyValue> = [
	KeyValue.ArrowUp,
	KeyValue.ArrowDown,
	KeyValue.ArrowLeft,
	KeyValue.ArrowRight,
	KeyValue.Home,
	KeyValue.End,
	KeyValue.Enter,
	KeyValue.Space,
];

@Directive({
	standalone: true,
	selector: '[globalNavMenuKeyboardNavigation]',
})
export class MenuKeyboardNavigationDirective implements AfterViewInit, OnChanges, OnInit {
	@Input() isKeyboardNavigationFocused = false;
	@Input() menuName: string;
	@Input() parentMenuName?: string;
	@Input() currentMenuItemIndex = 0;
	@Output() navigateToParentMenuEvent = new EventEmitter<string>();
	@Output() navigateToPriorMenuItemEvent = new EventEmitter<string>();
	@Output() navigateToNextMenuItemEvent = new EventEmitter<string>();
	@Output() navigateToFirstMenuItemEvent = new EventEmitter<string>();
	@Output() navigateToLastMenuItemEvent = new EventEmitter<string>();
	private currentMenuItemEle$ = new BehaviorSubject<HTMLElement | undefined>(undefined);
	currentMenuItem$ = this.currentMenuItemEle$.pipe(takeUntilDestroyed());

	constructor(private element: ElementRef) {}

	private get menuItems(): Array<HTMLElement> {
		return this.element.nativeElement.querySelectorAll('[role="menuitem"]');
	}

	@HostListener('window:keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
		const pressedKey = event.key as KeyValue;
		if (this.isKeyboardNavigationFocused && SUPPORT_KEYS.includes(pressedKey)) {
			event.preventDefault();
			event.stopPropagation();
			const action = this.getKeyAction(pressedKey);
			action?.call(this);
		}
	}

	ngOnInit(): void {
		this.currentMenuItem$.subscribe((currentMenuItem) => {
			this.resetElementsAriaStatus();
			if (this.isKeyboardNavigationFocused) {
				focusOnCurrentMenuItem(currentMenuItem);
			}
		});
	}

	ngAfterViewInit(): void {
		this.currentMenuItemEle$.next(this.menuItems[this.currentMenuItemIndex]);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('isKeyboardNavigationFocused') || changes.hasOwnProperty('currentMenuItemIndex')) {
			this.handleFocusAndMenuItemIndexChange();
		}
	}

	private handleFocusAndMenuItemIndexChange(): void {
		this.currentMenuItemEle$.next(this.menuItems[this.currentMenuItemIndex]);
	}

	private expandSubmenu(): void {
		const currentMenuItem = this.currentMenuItemEle$.getValue();
		if (currentMenuItem && hasSubmenu(currentMenuItem)) {
			setElementTabbable(currentMenuItem, false);
			currentMenuItem.click();
		}
	}

	private resetElementsAriaStatus(): void {
		this.menuItems.forEach((menuItem) => {
			setElementTabbable(menuItem, false);
		});
	}

	private triggerClick(): void {
		this.currentMenuItemEle$.getValue()?.click();
	}

	private getKeyAction(key: KeyValue): (() => void) | undefined {
		const actions: { [key in KeyValue]?: () => void } = {
			[KeyValue.ArrowUp]: () => this.navigateToPriorMenuItemEvent.emit(this.menuName),
			[KeyValue.ArrowDown]: () => this.navigateToNextMenuItemEvent.emit(this.menuName),
			[KeyValue.ArrowLeft]: () => this.navigateToParentMenuEvent.emit(this.parentMenuName),
			[KeyValue.ArrowRight]: this.expandSubmenu,
			[KeyValue.Home]: () => this.navigateToFirstMenuItemEvent.emit(this.menuName),
			[KeyValue.End]: () => this.navigateToLastMenuItemEvent.emit(this.menuName),
			[KeyValue.Enter]: this.triggerClick,
			[KeyValue.Space]: this.triggerClick,
		};

		return actions[key];
	}
}
