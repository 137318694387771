<ng-content></ng-content>
<ng-template cdkPortal #overlayTemplate="cdkPortal">
	<div *ngIf="isSmallScreen; else desktop" class="search-autocomplete-wrap fullscreen">
		<div class="search-autocomplete-content">
			<h5 class="searchAutocomplete-resultsHeader searchAutocomplete-resultsHeader--fullscreen" *ngIf="!!header">
				{{ header }}
			</h5>
			<cdx-option-list
				[listItems]="searchResults"
				[displayKey]="displayKey"
				[idPrefix]="idPrefix"
				(optionSelect)="onOptionSelect($event)"
				(optionFocus)="onOptionFocus($event)"
			>
				<ng-content select="[footer]"></ng-content>
			</cdx-option-list>
		</div>
	</div>
</ng-template>

<ng-template #desktop>
	<cdx-autocomplete-modal (closeEmitter)="closeOverlay()">
		<ng-container *ngIf="searchResults; else multiResults">
			<h5 class="searchAutocomplete-resultsHeader" *ngIf="!!header">{{ header }}</h5>
			<cdx-option-list
				[listItems]="searchResults"
				[displayKey]="displayKey"
				(optionSelect)="onOptionSelect($event)"
				(optionFocus)="onOptionFocus($event)"
				[idPrefix]="idPrefix"
			>
				<ng-content select="[footer]"></ng-content>
			</cdx-option-list>
		</ng-container>
		<ng-template #multiResults>
			<cdx-option-list-multi
				[listItems]="multiSearchResults"
				(optionSelect)="onOptionSelect($event)"
				[idPrefix]="idPrefix"
			>
				<ng-content select="[footer]"></ng-content>
			</cdx-option-list-multi>
		</ng-template>
	</cdx-autocomplete-modal>
</ng-template>
