/* eslint-disable @typescript-eslint/naming-convention */
import { NavigationItem } from '@woolworthsnz/trader-api';

export const mainNavMock = [
	{
		label: 'Browse',
		url: '/shop/browse/departments',
		navigationItems: [
			{
				label: 'Department',
				items: [
					{ id: 14, label: 'Christmas', url: '/shop/browse/christmas' },
					{ id: 1, label: 'Bakery', url: '/shop/browse/bakery' },
					{
						id: 2,
						label: 'Deli & chilled foods',
						url: '/shop/browse/deli-chilled-foods',
					},
					{
						id: 4,
						label: 'Fruit & vegetables',
						url: '/shop/browse/fruit-vegetables',
					},
					{ id: 5, label: 'Meat', url: '/shop/browse/meat' },
					{ id: 6, label: 'Seafood', url: '/shop/browse/seafood' },
					{ id: 8, label: 'Baby care', url: '/shop/browse/baby-care' },
					{
						id: 9,
						label: 'Baking & cooking',
						url: '/shop/browse/baking-cooking',
					},
					{
						id: 10,
						label: 'Biscuits & crackers',
						url: '/shop/browse/biscuits-crackers',
					},
					{
						id: 11,
						label: 'Breakfast foods',
						url: '/shop/browse/breakfast-foods',
					},
					{
						id: 12,
						label: 'Canned & prepared foods',
						url: '/shop/browse/canned-prepared-foods',
					},
					{
						id: 13,
						label: 'Chocolate, sweets & snacks',
						url: '/shop/browse/chocolate-sweets-snacks',
					},
					{
						id: 15,
						label: 'Cleaning & homecare',
						url: '/shop/browse/cleaning-homecare',
					},
					{
						id: 16,
						label: 'Clothing & manchester',
						url: '/shop/browse/clothing-manchester',
					},
					{
						id: 17,
						label: 'Drinks - hot & cold',
						url: '/shop/browse/drinks-hot-cold',
					},
					{
						id: 19,
						label: 'Frozen foods',
						url: '/shop/browse/frozen-foods',
					},
					{
						id: 22,
						label: 'Health & wellness',
						url: '/shop/browse/health-wellness',
					},
					{
						id: 23,
						label: 'Home & kitchenware',
						url: '/shop/browse/home-kitchenware',
					},
					{
						id: 24,
						label: 'Liquor - beer & cider',
						url: '/shop/browse/liquor-beer-cider',
					},
					{
						id: 25,
						label: 'Liquor - wine',
						url: '/shop/browse/liquor-wine',
					},
					{
						id: 26,
						label: 'Meal ingredients',
						url: '/shop/browse/meal-ingredients',
					},
					{
						id: 28,
						label: 'Office & entertainment',
						url: '/shop/browse/office-entertainment',
					},
					{
						id: 29,
						label: 'Personal care',
						url: '/shop/browse/personal-care',
					},
					{ id: 30, label: 'Pet care', url: '/shop/browse/pet-care' },
					{
						id: 33,
						label: 'Toys & party needs',
						url: '/shop/browse/toys-party-needs',
					},
				],
			},
		],
	},
	{ label: 'Specials', url: '/shop/specials' },
	{
		label: 'Favourites & lists',
		isNew: true,
		url: '/lists/myfavourites',
		icon: 'heart-default',
		navigationItems: [
			{
				items: [
					{
						id: 0,
						label: 'Shopping list',
						url: '/shop/editsearchlist',
					},
					{ id: 0, label: 'Favourites', url: '/lists/myfavourites' },
					{ id: 0, label: 'Past orders', url: '/lists/pastorders/all' },
					{ id: 0, label: 'Saved lists', url: '/lists/saved' },
					{ id: 0, label: 'Saved recipes', url: '/lists/recipes' },
				],
			},
		],
	},
	{ label: 'Recipes', url: '/recipes' },
	{ label: 'Absolute', url: 'https://www.google.co.nz/' },
	{
		label: 'Favourites & lists with ids',
		isNew: true,
		url: '/lists/myfavourites',
		icon: 'heart-default',
		navigationItems: [
			{
				items: [
					{
						id: 5,
						label: 'Shopping list',
						url: '/shop/editsearchlist',
					},
					{ id: 4, label: 'Favourites', url: '/lists/myfavourites' },
					{ id: 1, label: 'Past orders', url: '/lists/pastorders/all' },
					{ id: 3, label: 'Saved lists', url: '/lists/saved' },
					{ id: 2, label: 'Saved recipes', url: '/lists/recipes' },
				],
			},
		],
	},
];

export const embeddedNavMock = [
	{ label: 'Shop', url: '/' },
	{ label: 'Browse', url: '/shop/browse/departments' },
	{ label: 'Specials', url: '/shop/specials' },
	{
		label: 'Favourites & lists',
		isNew: true,
		url: '/lists/myfavourites',
		icon: 'heart-default',
		navigationItems: [
			{
				items: [
					{
						id: 0,
						label: 'Shopping list',
						url: '/shop/editsearchlist',
					},
					{ id: 0, label: 'Favourites', url: '/lists/myfavourites' },
					{ id: 0, label: 'Past orders', url: '/lists/pastorders/all' },
					{ id: 0, label: 'Saved lists', url: '/lists/saved' },
					{ id: 0, label: 'Saved recipes', url: '/lists/recipes' },
				],
			},
		],
	},
	{ label: 'Mailer', url: '/shop/content/weeklymailer' },
];

export const oldEmbeddedNavMock = [
	{ text: 'Shop', url: '/', exact: true },
	{ text: 'Browse', url: '/shop/browse/departments' },
	{ text: 'Specials', url: '/shop/specials' },
	{
		text: 'My Favourites',
		url: '/lists/myfavourites',
	},
];

export const oldMainNavMock = [
	{
		url: '/',
		text: 'Home',
	},
	{
		url: 'shop/browse',
		text: 'Browse',
		subnav: true,
	},
	{
		url: 'shop/specials',
		text: 'Specials',
		subnav: true,
	},
	{
		url: 'recipes',
		text: 'Recipes',
	},
	{
		url: `lists/myfavourites`,
		text: 'My Favourites',
	},
];

export const mobileNavMock = [
	{
		url: '/shop/editsearchlist',
		text: 'Shopping List',
	},
	{
		url: '/shop/accountdetails',
		text: 'My Account',
	},
	{
		url: `/store-finder`,
		text: 'Store Finder',
	},
	{
		url: '/shop/faqs',
		text: 'Help',
	},
	{
		url: '/shop/content/mycountdownapp',
		text: 'myCountdown App',
	},
	{
		url: '/about-us/contact-us',
		text: 'Contact',
	},
];

export const actionsNavMock = [
	{
		url: '/shop/accountdetails',
		text: 'My Account',
	},
	{
		url: '/shop/help',
		text: 'Help',
	},
	{
		url: '/about-us/contact-us',
		text: 'Contact',
	},
];

export const quickNavMock = [
	{
		url: '/about-us/contact-us',
		text: 'Contact',
	},
	{
		url: '/store-finder',
		text: 'Stores',
	},
];

export const dynamicHeaderLinkMock = {
	id: 1,
	label: 'Mailer',
	url: '/shop/content/weeklymailer',
	isEnabled: true,
};

export const browseMock = [
	{
		id: 14,
		label: 'Christmas',
		url: 'christmas',
	},
	{
		id: 1,
		label: 'Bakery',
		url: 'bakery',
	},
	{
		id: 2,
		label: 'Deli & chilled foods',
		url: 'deli-chilled-foods',
	},
	{
		id: 4,
		label: 'Fruit & vegetables',
		url: 'fruit-vegetables',
	},
	{
		id: 5,
		label: 'Meat',
		url: 'meat',
	},
	{
		id: 6,
		label: 'Seafood',
		url: 'seafood',
	},
	{
		id: 8,
		label: 'Baby care',
		url: 'baby-care',
	},
	{
		id: 9,
		label: 'Baking & cooking',
		url: 'baking-cooking',
	},
	{
		id: 10,
		label: 'Biscuits & crackers',
		url: 'biscuits-crackers',
	},
	{
		id: 11,
		label: 'Breakfast foods',
		url: 'breakfast-foods',
	},
	{
		id: 12,
		label: 'Canned & prepared foods',
		url: 'canned-prepared-foods',
	},
	{
		id: 13,
		label: 'Chocolate, sweets & snacks',
		url: 'chocolate-sweets-snacks',
	},
	{
		id: 15,
		label: 'Cleaning & homecare',
		url: 'cleaning-homecare',
	},
	{
		id: 16,
		label: 'Clothing & manchester',
		url: 'clothing-manchester',
	},
	{
		id: 17,
		label: 'Drinks - hot & cold',
		url: 'drinks-hot-cold',
	},
	{
		id: 19,
		label: 'Frozen foods',
		url: 'frozen-foods',
	},
	{
		id: 22,
		label: 'Health & wellness',
		url: 'health-wellness',
	},
	{
		id: 23,
		label: 'Home & kitchenware',
		url: 'home-kitchenware',
	},
	{
		id: 24,
		label: 'Liquor - beer & cider',
		url: 'liquor-beer-cider',
	},
	{
		id: 25,
		label: 'Liquor - wine',
		url: 'liquor-wine',
	},
	{
		id: 26,
		label: 'Meal ingredients',
		url: 'meal-ingredients',
	},
	{
		id: 28,
		label: 'Office & entertainment',
		url: 'office-entertainment',
	},
	{
		id: 29,
		label: 'Personal care',
		url: 'personal-care',
	},
	{
		id: 30,
		label: 'Pet care',
		url: 'pet-care',
	},
	{
		id: 33,
		label: 'Toys & party needs',
		url: 'toys-party-needs',
	},
];

export const specialsMock = [
	{
		id: 2,
		label: 'Deli & chilled foods (10)',
		url: 'deli-chilled-foods',
	},
	{
		id: 5,
		label: 'Meat (35)',
		url: 'meat',
	},
	{
		id: 6,
		label: 'Seafood (9)',
		url: 'seafood',
	},
	{
		id: 11,
		label: 'Breakfast foods (3)',
		url: 'breakfast-foods',
	},
	{
		id: 29,
		label: 'Personal care (15)',
		url: 'personal-care',
	},
];

export const myAccountNavMock = [
	{
		label: 'Online Shopping',
		items: [
			{
				url: '/shop/accountdetails',
				label: 'My account details',
				shopper: true,
			},
			{
				url: '/shop/orders',
				label: 'My orders',
				shopper: true,
			},
			{
				url: '/shop/mydeliverysavers',
				label: 'My delivery savers',
				shopper: true,
			},
			{
				url: '/shop/subscriptions',
				label: 'My subscriptions',
				shopper: true,
			},
			// not shopper
			{
				url: '/shop/register',
				label: 'Register for Online shopping',
				shopper: false,
			},
		],
	},
	{
		label: 'Onecard',
		items: [
			{
				url: '/onecard/dashboard',
				label: 'Onecard dashboard',
				onecard: true,
			},
			{
				url: '/onecard/my-onecard/personal-settings',
				label: 'Onecard profile',
				onecard: true,
			},
			{
				url: '/onecard/cards',
				label: 'Manage your cards',
				onecard: true,
			},
			// not onecard registered
			{
				url: '/shop/register',
				label: 'Register  a new Onecard',
				onecard: false,
			},
			// Should come after register if it's displayed
			{
				url: '/shop/manageonecard',
				label: 'Manage Onecard for Online shopping',
				shopper: true,
			},
		],
	},
	{
		label: 'Preferences',
		items: [
			{
				url: '/onecard/my-onecard/preference-hub',
				label: 'Email and SMS preferences',
				onecard: true,
			},
		],
	},
];

export const footerNavMock = [
	{
		label: 'Save at Countdown',
		items: [
			{
				id: 0,
				label: 'COVID 19 Information',
				url: '/community-environment/covid-19',
			},
			{
				id: 1,
				label: 'Mailer',
				url: '/shop/content/weeklymailer',
			},
			{
				id: 2,
				label: 'Boosts',
				url: '/defaultlink',
			},
		],
	},
	{
		label: 'Stores, hours and services',
		items: [
			{
				id: 0,
				label: 'Store locations and hours',
				url: '/store-finder',
			},
			{
				id: 1,
				label: 'Metro stores',
				url: '/shopping-made-easy/metro',
			},
			// {
			// 	id: 2,
			// 	label: 'Scan&Go',
			// 	url: ''
			// },
			{
				id: 3,
				label: 'Gift cards',
				url: '/shopping-made-easy/countdown-gift-cards',
			},
			{
				id: 4,
				label: 'Christmas Club',
				url: '/christmasclub',
			},
			// {
			// 	id: 5,
			// 	label: 'More services',
			// 	url: ''
			// },
		],
	},
	{
		label: 'Customer service',
		items: [
			{
				id: 0,
				label: 'Help and FAQ',
				url: '/shop/faqs',
			},
			{
				id: 1,
				label: 'New to online shopping?',
				url: '/shop/content/new-to-online-shopping',
			},
			{
				id: 2,
				label: 'Contact us',
				url: '/about-us/contact-us',
			},
			{
				id: 3,
				label: 'Manage account',
				url: '/shop/managingyouraccounthelp',
			},
		],
	},
	{
		label: 'About Countdown',
		items: [
			{
				id: 0,
				label: 'Community and environment',
				url: '/community-environment',
			},
			{
				id: 1,
				label: 'Careers',
				url: '/info/careers',
			},
			{
				id: 2,
				label: 'News and media releases',
				url: '/info/news-and-media-releases',
			},
			{
				id: 3,
				label: 'About us',
				url: '/about-us',
			},
			// {
			// 	id: 4,
			// 	label: 'Supplier information',
			// 	url: ''
			// },
			{
				id: 5,
				label: 'Our policies',
				url: '/about-us/our-policies',
			},
		],
	},
];

export const legalBarNavMock: NavigationItem[] = [
	{
		id: 0,
		label: 'Liquor licenses',
		url: '/shop/content/liquor-licences',
		promoTiles: [],
	},
	{
		id: 1,
		label: 'Privacy centre',
		url: '/shop/privacypolicy',
		promoTiles: [],
	},
	{
		id: 2,
		label: `Terms & conditions`,
		url: '/shop/termsandconditions',
		promoTiles: [],
	},
];

export const expressMock = [
	{
		label: '',
		labels: { '1365': 'Express', '1366': 'Express' },
		url: '/',
	},
];

export const expressMockEmbedded = [
	{
		label: 'Shop',
		labels: { '1365': 'Express', '1366': 'Express' },
		url: '/',
	},
];

export const browseNavItemMock = {
	label: 'Browse',
	url: '/shop/browse/departments',
	navigationItems: [
		{
			label: 'Department',
			items: [
				{
					iconUrl: '/images/global-nav/nav-experiment-v2/bakery.png',
					id: 1,
					label: 'Bakery',
					url: '//countdown.co.nz/shop/browse/bakery',
					dasFacets: [
						{
							key: '1',
							name: 'Cakes & Desserts',
						},
					],
				},
				{
					iconUrl: '/images/global-nav/nav-experiment-v2/fruit---veg.png',
					id: 2,
					label: 'Fruit & Veg',
					url: '//countdown.co.nz/shop/browse/fruit-veg',
					dasFacets: [
						{
							key: '1',
							name: 'Fresh Herbs & Spices',
						},
					],
				},
			],
		},
	],
};

export const browseNavItemMockWithPromoTiles = {
	label: 'Browse',
	url: '/shop/browse/departments',
	navigationItems: [
		{
			label: 'Department',
			items: [
				{
					iconUrl: '/images/global-nav/nav-experiment-v2/bakery.png',
					id: 1,
					label: 'Bakery',
					url: '//countdown.co.nz/shop/browse/bakery',
					dasFacets: [
						{
							key: '1',
							name: 'Cakes & Desserts',
						},
					],
					promoTiles: [
						{
							id: 1,
							title: 'Bakery tile',
							navigationUrl: 'shop/browse/bakery',
							imageLink: '/images/global-nav/nav-experiment-v2/bakery.png',
							order: 1,
						},
					],
				},
			],
			promoTiles: [
				{
					id: 1,
					title: '3 for 20',
					navigationUrl: '/shop/browse/meat-poultry/3-for-20',
					imageLink: '/images/global-nav/nav-experiment-v2/3for20_desktop.png',
					order: 1,
				},
			],
		},
	],
};

export const browseNavAisleItemMockWithPromoTiles = {
	label: 'Browse',
	url: '/shop/browse/departments',
	navigationItems: [
		{
			label: 'Department',
			items: [
				{
					iconUrl: '/images/global-nav/nav-experiment-v2/bakery.png',
					id: 1,
					label: 'Bakery',
					url: '//countdown.co.nz/shop/browse/bakery',
					dasFacets: [
						{
							key: '7',
							value: '63',
							isBooleanValue: false,
							name: 'Oil, Vinegar & Condiments',
							productCount: 389,
							shelfResponses: [
								{
									id: 162,
									label: 'Oil',
									url: 'oil',
								},
								{
									id: 163,
									label: 'Vinegar',
									url: 'vinegar',
								},
								{
									id: 164,
									label: 'Dressings',
									url: 'dressings',
								},
							],
							group: 'Aisle',
							promoTiles: [
								{
									id: 39,
									title: 'Specials Pantry',
									navigationUrl:
										'https://wwwsit.countdown.co.nz/shop/specials/pantry?search=&page=1&size=120&sort=BrowseRelevance&dasFilter=Department;7;Pantry;false;Department&inStockProductsOnly=true',
									imageLink:
										'https://wwwsit.countdown.co.nz/content/browsemenu/BRW-specials-pantry.png',
									order: 0,
									active: true,
									startDate: undefined,
									endDate: undefined,
								},
							],
						},
					],
					promoTiles: [
						{
							id: 1,
							title: 'Bakery tile',
							navigationUrl: 'shop/browse/bakery',
							imageLink: '/images/global-nav/nav-experiment-v2/bakery.png',
							order: 1,
						},
					],
				},
			],
			promoTiles: [
				{
					id: 1,
					title: '3 for 20',
					navigationUrl: '/shop/browse/meat-poultry/3-for-20',
					imageLink: '/images/global-nav/nav-experiment-v2/3for20_desktop.png',
					order: 1,
				},
			],
		},
	],
};

export const otherNavItemsMock = [
	{
		icon: 'past-orders',
		label: 'Past orders',
		url: '/lists/pastorders/all',
	},
	{
		icon: 'recipes',
		label: 'Recipes',
		url: '/recipes',
	},
];

export const productShelvesMock = [
	{
		id: 502,
		label: 'Batteries',
		url: 'batteries',
	},
	{
		id: 503,
		label: 'Light Bulbs & Torches',
		url: 'light-bulbs-torches',
	},
];

export const featureProductStampsMock = [
	{ shelve: 'Baking', sku: '419719' },
	{ shelve: 'Milk & Cream', sku: '61659' },
];
