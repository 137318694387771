import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ButtonComponent, IconFill, SvgIconComponent } from '@woolworthsnz/styleguide';

@Component({
	selector: 'global-nav-header',
	template: `
		<header>
			<button
				cdxButton
				[inline]="true"
				fillStyle="link"
				class="header-drawerButton"
				(click)="openMobileNav.emit()"
				aria-label="Show mobile navigation"
			>
				<cdx-svg-icon [name]="menuIcon" [fill]="iconFill"></cdx-svg-icon>
			</button>
			<div class="header-image--mobile">
				<ng-content select="[mobile-image]"></ng-content>
			</div>
			<div class="header-image--desktop">
				<ng-content select="[desktop-image]"></ng-content>
			</div>
			<div class="header-item--desktop navExperimentV1">
				<ng-content select="[desktop-start-slot]"></ng-content>
			</div>
			<div class="header-item--desktop">
				<ng-content select="[desktop-end-slot]"></ng-content>
			</div>
			<div class="header-item--tablet">
				<ng-content select="[tablet-end-slot]"></ng-content>
			</div>
			<div class="header-item--mobile">
				<ng-content select="[mobile-end-slot]"></ng-content>
			</div>
		</header>
	`,
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ButtonComponent, SvgIconComponent]
})
export class HeaderComponent {
	@Output() openMobileNav = new EventEmitter<void>();

	@Input() iconFill: IconFill = 'dark';
	@Input() menuIcon = 'menu';

	@HostBinding('attr.data-align')
	@Input()
	align: 'center' | 'left' = 'center';
}
