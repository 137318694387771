import { AsyncPipe, CurrencyPipe, NgIf, TitleCasePipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Optional,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import {
	kebabCase,
	CapitalizeSpecificWordsPipe,
	SrPunctuationPipe,
	DeferLoadDirective,
	JoinPipe,
	ButtonComponent,
	NavigationItemGroupWithAnalytics,
	FlagKey,
	FlagService,
	MegaMenuProductDiscovery,
	TrackImpressionDirective,
	PromoTileImpressionArgs,
	TRADER_BASE_URL,
} from '@woolworthsnz/styleguide';
import { BehaviorSubject, combineLatest, Observable, Subject, takeUntil } from 'rxjs';

interface SmallTileProduct {
	sku: string;
	src: string;
	name: string;
}

@Component({
	selector: 'global-nav-feature-mega-menu-small-tile',
	templateUrl: './feature-mega-menu-small-tile.component.html',
	styleUrls: ['./feature-mega-menu-small-tile.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		AsyncPipe,
		CurrencyPipe,
		NgIf,
		RouterLink,
		TitleCasePipe,
		CapitalizeSpecificWordsPipe,
		SrPunctuationPipe,
		DeferLoadDirective,
		JoinPipe,
		ButtonComponent,
		TrackImpressionDirective,
	],
})
export class FeatureMegaMenuSmallTileComponent implements OnInit, OnDestroy {
	@Input()
	public aislesNavItems$ = new BehaviorSubject<NavigationItemGroupWithAnalytics[] | undefined>(undefined);
	@Input()
	public shelvesNavItems$ = new BehaviorSubject<NavigationItemGroupWithAnalytics[] | undefined>(undefined);
	@Input()
	public width: number;
	public isVisible$ = new BehaviorSubject<boolean>(false);
	public product: SmallTileProduct | undefined;
	public hrefLink = '';
	private megaMenuProductDiscoveryVarient$: Observable<string>;

	private destroyed$: Subject<boolean> = new Subject();
	@Output() productClicked = new EventEmitter<{ department: string; aisle: string | undefined; sku: string }>();
	promoImpressionArgs: Map<string, PromoTileImpressionArgs> = new Map();

	private products: SmallTileProduct[] = [
		{
			sku: '281677',
			src: '/images/global-nav/mega-menu-recipe-stamp/small-tile-mainland-butter.jpg',
			name: 'mainland butter',
		},
		{
			sku: '297515',
			src: '/images/global-nav/mega-menu-recipe-stamp/small-tile-bluebird-ready-salted.jpg',
			name: 'bluebird originals potato chips ready salted',
		},
	];

	private departments = [
		{ department: 'Pantry', sku: '297515' },
		{ department: 'Fridge & Deli', sku: '281677' },
	];
	private aisles = [
		{ aisle: 'Snacks & Sweets', sku: '297515' },
		{ aisle: 'Eggs, Butter & Spreads', sku: '281677' },
	];

	constructor(
		private flagService: FlagService,
		@Optional() @Inject(TRADER_BASE_URL) public baseDomain: string
	) {}

	ngOnInit(): void {
		this.megaMenuProductDiscoveryVarient$ = this.flagService.getVariationKey(FlagKey.megaMenuProductDiscovery);
		combineLatest([this.megaMenuProductDiscoveryVarient$, this.aislesNavItems$])
			.pipe(takeUntil(this.destroyed$))
			.subscribe(([megaMenuProductDiscoveryVarient, aislesNavItems]) => {
				this.showDepartmentProducts(megaMenuProductDiscoveryVarient, aislesNavItems);
			});

		combineLatest([this.megaMenuProductDiscoveryVarient$, this.shelvesNavItems$])
			.pipe(takeUntil(this.destroyed$))
			.subscribe(([megaMenuProductDiscoveryVarient, shelvesNavItems]) => {
				this.showAislesProducts(megaMenuProductDiscoveryVarient, shelvesNavItems);
			});
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
		this.isVisible$.complete();
	}

	get nameInKebabCase(): string {
		return kebabCase(this.product?.name || '');
	}

	private showDepartmentProducts(
		megaMenuProductDiscoveryVarient: string,
		aislesNavItems: NavigationItemGroupWithAnalytics[] | undefined
	): void {
		this.clearPromoImpressions();
		if (aislesNavItems && megaMenuProductDiscoveryVarient === MegaMenuProductDiscovery.Variation1) {
			const department = this.departments.find((d) => d.department === aislesNavItems[0]?.label);
			if (department) {
				this.product = this.products.find((p) => p.sku === department.sku);
				this.hrefLink = this.getHrefLink();
				const departmentLabel = department.department;
				const aisleLabel = undefined;
				this.onProductTileClick(departmentLabel, aisleLabel, department.sku);

				// Force trigger promo impressions by resetting and then setting up
				setTimeout(() => {
					this.setupPromoImpressions(this.product?.name ?? '', departmentLabel, aisleLabel);
					this.isVisible$.next(true); // Ensure visibility is set after promo impressions
				}, 0);
				return;
			}
		}

		this.isVisible$.next(false);
	}

	private clearPromoImpressions(): void {
		// This method clears any previous promo impressions before setting up new ones
		this.promoImpressionArgs.clear();
		this.isVisible$.next(false);
	}

	private setupPromoImpressions(
		promoTileName: string,
		departmentLabel: string | undefined,
		aisleLabel: string | undefined
	): void {
		const promotionId = this.getPromotionId(departmentLabel, aisleLabel);
		let creativeName = 'Browse Menu';
		if (departmentLabel) {
			creativeName += ` | ${departmentLabel}`;
		}
		if (aisleLabel) {
			creativeName += ` | ${aisleLabel}`;
		}
		const oddBunchData: PromoTileImpressionArgs = {
			creativeName: creativeName,
			creativeSlot: '4',
			promotionId: promotionId,
			promotionName: promoTileName,
		};

		this.promoImpressionArgs.set(promoTileName, oddBunchData);
	}

	private getPromotionId(departmentLabel: string | undefined, aisleLabel: string | undefined): string {
		if (!aisleLabel) {
			if (departmentLabel === 'Fridge & Deli') {
				return 'AB166-1';
			}
			if (departmentLabel === 'Pantry') {
				return 'AB166-2';
			}
		} else {
			if (departmentLabel === 'Fridge & Deli' && aisleLabel === 'Eggs, Butter & Spreads') {
				return 'AB166-3';
			}
			if (departmentLabel === 'Pantry' && aisleLabel === 'Snacks & Sweets') {
				return 'AB166-4';
			}
		}

		return '';
	}

	onProductTileClick(department: string, aisle: string | undefined, sku: string): void {
		this.productClicked.emit({ department, aisle, sku });
	}

	private showAislesProducts(
		megaMenuProductDiscoveryVarient: string,
		shelvesNavItems: NavigationItemGroupWithAnalytics[] | undefined
	): void {
		if (shelvesNavItems && megaMenuProductDiscoveryVarient === MegaMenuProductDiscovery.Variation2) {
			const aisle = this.aisles.find((d) => d.aisle === shelvesNavItems[0]?.label);
			if (aisle) {
				this.product = this.products.find((p) => p.sku === aisle.sku);
				this.hrefLink = this.getHrefLink();
				const departmentLabel =
					this.departments.find((dept) => dept.sku === aisle.sku)?.department ?? 'undefined';
				const aisleLabel = aisle.aisle;
				this.onProductTileClick(departmentLabel, aisleLabel, aisle.sku);

				this.setupPromoImpressions(this.product?.name ?? '', departmentLabel, aisleLabel);
				this.isVisible$.next(true);
				return;
			}
		}
		this.isVisible$.next(false);
	}

	private getHrefLink(): string {
		return `${this.baseDomain ?? ''}/shop/productdetails?stockcode=${this.product?.sku}&name=${this.nameInKebabCase}`;
	}
}
