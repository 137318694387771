<nav id="nav-container">
	<ul data-cy="navigationBar" #navList>
		<ng-container *ngIf="navItems$ | async as navItems">
			<li *ngFor="let item of navItems; let i = index; trackBy: trackByFn" #navElements>
				<ng-container *ngIf="(isLargeDevice$ | async) && isBrowseNavItem(item); else normalNav">
					<cdx-popup
						*ngIf="browseNavItem$ | async as item"
						popupActiveClass="popupOpen"
						(modalOpen)="handleOpenChange($event, 0)"
						customClass="browseMenuExperimentMegaMenu"
						popupId="mainNavStreamlinedPopup"
						scrollStrategy="block"
					>
						<button
							type="button"
							fillStyle="tertiaryFlat"
							startSlotSize="small"
							size="small"
							[startSlotActive]="!!item.icon"
							[cdxTrackEvent]="handleTrackEvent(item)"
							cdxButton
							href="javascript:void(0);"
							cdxCutoutModalTarget="item.isNew"
							cdxPopupTarget
							cdxButton
							cdxCutoutModalTarget="item.isNew"
						>
							<cdx-svg-icon
								[name]="item.icon"
								size="small"
								*ngIf="item.icon"
								fill="currentColor"
								start
							></cdx-svg-icon>
							<span>{{ item.label }}</span>
						</button>
						<div class="card-content" cardContent>
							<global-nav-browse-menu
								#browseMenu
								[isDesktopNav]="true"
								[browseNavItems]="item.navigationItems"
								[otherNavItems]="filteredNavItemsShownInBrowse$ | async"
								[trackingName]="item.label"
								[isOpen]="isMenuOpen"
							></global-nav-browse-menu>
						</div>
					</cdx-popup>
				</ng-container>
				<ng-template #normalNav>
					<cdx-popup
						*ngIf="(isLargeDevice$ | async) && item.navigationItems; else linked"
						popupActiveClass="popupOpen"
						[customClass]="'transformPopup'"
						(modalOpen)="handleOpenChange($event, i)"
						popupId="mainNavPopup"
					>
						<button
							type="button"
							fillStyle="tertiaryFlat"
							startSlotSize="small"
							size="small"
							[startSlotActive]="!!item.icon"
							[cdxTrackEvent]="handleTrackEvent(item)"
							cdxPopupTarget
							cdxButton
							cdxCutoutModalTarget="item.isNew"
						>
							<cdx-svg-icon
								[name]="item.icon"
								size="small"
								*ngIf="item.icon"
								fill="currentColor"
								start
							></cdx-svg-icon>
							<span>{{ item.label }}</span>
						</button>
						<global-nav-links
							cardContent
							itemSpacing="dense"
							[linkGroups]="item.navigationItems"
							[trackingName]="item.label"
						></global-nav-links>
					</cdx-popup>
					<ng-template #linked>
						<ng-container *ngIf="isURLAbsolute(item.url); else relative">
							<a
								fillStyle="tertiaryFlat"
								startSlotSize="small"
								size="small"
								[startSlotActive]="!!item.icon"
								[cdxTrackEvent]="handleTrackEvent(item)"
								[href]="item.url"
								[ngClass]="{ 'mainNav-linked--small': isEmbedded$ | async }"
								cdxButton
								cdxCutoutModalTarget="item.isNew"
								(click)="onClickLink($event, item)"
							>
								<cdx-svg-icon
									[name]="item.icon"
									size="small"
									*ngIf="item.icon"
									fill="currentColor"
									start
								></cdx-svg-icon>
								<span>{{ item.label }}</span>
							</a>
						</ng-container>

						<ng-template #relative>
							<ng-container
								*ngIf="(isEmbedded$ | async) === false && isBrowseNavItem(item); else withLink"
							>
								<a
									fillStyle="tertiaryFlat"
									startSlotSize="small"
									size="small"
									data-cy="browseNavItem"
									[startSlotActive]="!!item.icon"
									[cdxTrackEvent]="handleTrackEvent(item)"
									[ngClass]="{ 'mainNav-linked--small': isEmbedded$ | async }"
									cdxButton
									endSlotSize="extra-small"
									cdxCutoutModalTarget="item.isNew"
									(click)="onClickLink($event, item)"
								>
									<cdx-svg-icon
										[name]="item.icon"
										size="small"
										*ngIf="item.icon"
										fill="currentColor"
										start
									></cdx-svg-icon>
									<span>{{ item.label }}</span>
								</a>
							</ng-container>
							<ng-template #withLink>
								<a
									fillStyle="tertiaryFlat"
									startSlotSize="small"
									size="small"
									[startSlotActive]="!!item.icon"
									[cdxTrackEvent]="handleTrackEvent(item)"
									[routerLink]="item.url"
									routerLinkActive="active"
									[routerLinkActiveOptions]="{ exact: item.url === '/' }"
									[ngClass]="{ 'mainNav-linked--small': isEmbedded$ | async }"
									cdxButton
									endSlotSize="extra-small"
									cdxCutoutModalTarget="item.isNew"
								>
									<cdx-svg-icon
										[name]="item.icon"
										size="small"
										*ngIf="item.icon"
										fill="currentColor"
										start
									></cdx-svg-icon>
									<span>{{ item.label }}</span>
								</a>
							</ng-template>
						</ng-template>
					</ng-template>
				</ng-template>
			</li>
		</ng-container>
	</ul>
</nav>
