import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostBinding,
	Inject,
	Input,
	OnInit,
	Optional,
	Renderer2,
} from '@angular/core';
import { FulfilmentStoreService } from '@woolworthsnz/fulfilment';
import {
	EDR_BOOST_OFFERS_PATH,
	EDR_LANDING_PAGE_PATH,
	FAVOURITES_PATH,
	LISTS_PATH,
	PAST_ORDERS_PATH,
	SAVED_LISTS_PATH,
	SAVED_RECIPES_PATH,
	ShellService,
	SHOPPING_LISTS_PATH,
	SPECIALS_PATH,
	STORE_LOCATOR_PATH,
} from '@woolworthsnz/shop';
import {
	ACCOUNT_BASE_URL,
	AppSettingsService,
	FeatureService,
	FeatureState,
	FlagService,
	NavigationItemGroupWithAnalytics,
	ShopperService,
} from '@woolworthsnz/styleguide';
import { ContextResponse, NavigationItem, NavigationItemGroup } from '@woolworthsnz/trader-api';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SocialsComponent } from '../socials/socials.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { LinksComponent } from '../links/links.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'global-nav-footer-links',
	templateUrl: './footer-links.component.html',
	styleUrls: ['./footer-links.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [LinksComponent, NgIf, SocialsComponent, AsyncPipe],
})
export class FooterLinksComponent implements OnInit {
	@HostBinding('attr.data-orientation')
	@Input()
	orientation: 'horizontal' | 'vertical' = 'horizontal';

	specialsLink = SPECIALS_PATH;
	favouritesLink = `${LISTS_PATH}/${FAVOURITES_PATH}`;
	savedListsLink = `${LISTS_PATH}/${SAVED_LISTS_PATH}`;
	pastOrdersLink = `${LISTS_PATH}/${PAST_ORDERS_PATH}`;
	savedRecipesLink = `${LISTS_PATH}/${SAVED_RECIPES_PATH}`;
	storeLocatorLink = STORE_LOCATOR_PATH;
	shoppingListLink = `/${SHOPPING_LISTS_PATH}`;

	fulfilmentMethod: string;
	footerLinks: { [key: string]: NavigationItem } = {};
	storeLinksTitle$: Observable<string>;

	linkGroups$: Observable<NavigationItemGroupWithAnalytics[]>;

	private isAuthenticated$: Observable<boolean>;
	fulfilmentStoreState$ = this.fulfilmentStoreService.state$.pipe(takeUntilDestroyed());
	shellServiceState$ = this.shellService.state$.pipe(takeUntilDestroyed());

	constructor(
		private appSettingsService: AppSettingsService,
		private renderer: Renderer2,
		private fulfilmentStoreService: FulfilmentStoreService,
		private shellService: ShellService,
		private cdr: ChangeDetectorRef,
		private featureService: FeatureService,
		private shopperService: ShopperService,
		@Optional() @Inject(ACCOUNT_BASE_URL) private accountBaseUrl: string,
		private flagService: FlagService
	) {}

	get canShowSpecials(): any {
		return this.appSettingsService.getSetting('showSpecials');
	}

	ngOnInit(): void {
		this.isAuthenticated$ = this.shopperService.state$.pipe(map((shopper) => !!shopper.isLoggedIn));

		this.fulfilmentStoreState$.subscribe((r) => {
			if (r.method) {
				this.fulfilmentMethod = r.method === 'Courier' ? 'Delivery' : r.method;
			}

			this.cdr.markForCheck();
		});

		this.shellServiceState$.subscribe(() => {
			this.footerLinks = this.shellService.state.footerLinks || {};
			this.cdr.markForCheck();
		});

		this.assignLinkGroups();

		this.storeLinksTitle$ = this.featureService
			.isEnabled(ContextResponse.EnabledFeaturesEnum.RebrandInHeaderAndFooter)
			.pipe(map((enabled) => `Get the ${enabled ? 'Woolworths NZ' : 'myCountdown'} app`));
	}

	toggleShowNavClass = (event: { target: any }): void => {
		const target = event.target;
		const cls = 'showChild';
		const hasClass = target.classList.contains(cls);

		if (hasClass) {
			this.renderer.removeClass(target, cls);
		} else {
			this.renderer.addClass(target, cls);
		}
	};

	private assignLinkGroups(): void {
		this.linkGroups$ = combineLatest([this.featureService.state$, this.isAuthenticated$]).pipe(
			map(([featureState, isAuthenticated]) => {
				const rawLinks: NavigationItemGroup[] = this.appSettingsService.getSetting('navs')?.onesiteFooterNav;
				const withRebreading = this.applyRebrandingIfApplicable(featureState, rawLinks);
				return this.setBoostsLink(isAuthenticated, withRebreading);
			})
		);
	}

	private applyRebrandingIfApplicable(featureState: FeatureState, links: any): any {
		if (featureState.enabledFeatures.includes(ContextResponse.EnabledFeaturesEnum.RebrandInHeaderAndFooter)) {
			return links?.map((link: NavigationItemGroup) => {
				if (link?.label?.includes('Countdown')) {
					return { ...link, label: link.label.replace('Countdown', 'Woolworths') };
				}
				return link;
			});
		}

		return links;
	}

	private setBoostsLink(isAuthenticated: boolean, links: any): any {
		const boostsLink = isAuthenticated
			? `/${EDR_BOOST_OFFERS_PATH}`
			: `${this.accountBaseUrl ?? ''}/${EDR_LANDING_PAGE_PATH}`;

		return links?.map((link: NavigationItemGroup) => {
			if (link?.items?.find((item) => item.label === 'Boosts')) {
				return {
					...link,
					items: link.items?.map((item) => {
						if (item.label === 'Boosts') {
							return { ...item, url: boostsLink };
						}
						return item;
					}),
				};
			}
			return link;
		});
	}
}
