import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostBinding,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Optional,
	ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import { InputComponent, InputSize, InputWithIconButtonComponent } from '@woolworthsnz/form';
import { SearchSuggestionsService } from '@woolworthsnz/shop';
import {
	AppSettingsService,
	BreakPointService,
	BridgeEvent,
	CustomWindow,
	DatalayerService,
	FeatureService,
	NativeBridgeService,
	SearchType,
	ShopperService,
	TRADER_BASE_URL,
	WINDOW,
	ButtonComponent,
	EmbeddedVisibilityDirective,
	SvgIconComponent
} from '@woolworthsnz/styleguide';
import { ContextResponse } from '@woolworthsnz/trader-api';
import { combineLatest, merge, Observable, Subject, Subscription } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { SearchFormAutocompleteComponent } from '../search-form-autocomplete/search-form-autocomplete.component';
import { tealiumSearchTrackingData } from './search.constants';
import { NgClass, NgIf, NgFor, NgTemplateOutlet, AsyncPipe, KeyValuePipe } from '@angular/common';
import EnabledFeaturesEnum = ContextResponse.EnabledFeaturesEnum;

@Component({
	selector: 'global-nav-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [EmbeddedVisibilityDirective, SvgIconComponent, FormsModule, NgClass, ReactiveFormsModule, SearchFormAutocompleteComponent, InputWithIconButtonComponent, ButtonComponent, NgIf, NgFor, NgTemplateOutlet, AsyncPipe, KeyValuePipe]
})
export class SearchComponent implements OnInit, OnDestroy {
	@ViewChild('input', { static: true }) searchInputElement: InputComponent;
	@ViewChild(SearchFormAutocompleteComponent) autocomplete: SearchFormAutocompleteComponent;

	@Input() subnav: ElementRef;
	searchTypeEnum = SearchType;
	searchForm: FormGroup;
	searchSuggestions: any[];
	searchParam = false;
	isMobile = false;
	blurred = true;
	focussedSuggestion: string;
	suggestionIdentifierPrefix = 'searchSuggestion_';
	scanToTrolleyEnabled$: Observable<boolean>;
	resultsHeader = '';

	hasInputtedText$: Observable<boolean>;
	suggestionsSubscription: Subscription;
	hasInputtedText = false;
	private urlTracker = '';
	private destroyed$: Subject<boolean> = new Subject();

	constructor(
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private featureService: FeatureService,
		private searchSuggestionService: SearchSuggestionsService,
		private router: Router,
		private breakpointService: BreakPointService,
		private nativeBridgeService: NativeBridgeService,
		private shopperService: ShopperService,
		private appSettingsService: AppSettingsService,
		private datalayerService: DatalayerService,
		private tealiumService: TealiumUtagService,
		@Optional() @Inject(TRADER_BASE_URL) private traderBaseUrl: string,
		@Inject(WINDOW) private window: CustomWindow
	) { }


	@HostBinding('class.focussed')
	get isFocussed(): boolean {
		// focussed / expanded state only when on mobile and there is any search value
		return this.isMobile && !!this.searchValue;
	}

	get searchValue(): string {
		return this.searchForm.get('search')?.value;
	}

	get searchType(): SearchType {
		return this.searchForm.get('searchType')?.value;
	}

	get searchQuery(): string {
		return this.route.snapshot.queryParamMap.get(this.searchType === SearchType.Recipes ? 'q' : 'search') || '';
	}

	get placeholder(): string {
		if (!this.blurred) {
			return `Search ${this.searchType === this.searchTypeEnum.Grocery ? 'Groceries' : 'Recipes'}`;
		}
		return 'Search';
	}

	get size(): string {
		return InputSize.fullWidth;
	}

	get isEmbeddedInApp(): string {
		return this.appSettingsService.getSetting('isEmbeddedInApp');
	}

	ngOnInit(): void {
		this.searchForm = this.fb.group({
			search: '',
			searchType: SearchType.Grocery,
		});
		this.searchInputElement.focus.pipe(takeUntil(this.destroyed$)).subscribe(() => (this.blurred = false));
		this.searchInputElement.blur.pipe(takeUntil(this.destroyed$)).subscribe(() => (this.blurred = true));

		this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((routerEvent) => {
			if (routerEvent instanceof NavigationEnd) {
				if (this.isInRecipesSection(routerEvent.urlAfterRedirects)) {
					this.searchForm.patchValue({ searchType: SearchType.Recipes });
				} else {
					this.searchForm.patchValue({ searchType: SearchType.Grocery });
				}

				/** chmfp-182-search-clear-bug
				 * This is a workaround to prevent the search input field from being updated multiple times when
				 * using the back button to navigate to the home page. */
				if (this.urlTracker === '') {
					this.urlTracker = routerEvent.url; // Initialize the urlTracker
				}

				if (this.urlTracker !== routerEvent.url) {
					this.urlTracker = routerEvent.url;
					this.setSearchTermToMatchSearchQueryStringParam(this.searchQuery);
				}
				this.cdr.markForCheck();
			}
		});

		if (this.isInRecipesSection(this.router.url)) {
			this.searchForm.patchValue({ searchType: SearchType.Recipes });
		} else {
			this.searchForm.patchValue({ searchType: SearchType.Grocery });
		}

		this.breakpointService.isSmallDevice$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.scanToTrolleyEnabled$ = this.featureService.state$.pipe(
			map((s) => s.enabledFeatures.includes(EnabledFeaturesEnum.ScanToTrolley) && !!this.isEmbeddedInApp)
		);

		this.subscribeToSearchSuggestionResponse();

		this.searchSuggestionService.fetchTopProductSuggestions().pipe(takeUntil(this.destroyed$)).subscribe();

		// opens the suggestion box with the top suggestions when the box is focussed and empty
		combineLatest([
			merge(this.searchInputElement.focus, this.searchInputElement.blur), // combined so we always get a focus then the corresponding blur
			this.searchForm.controls.search.valueChanges.pipe(startWith(this.searchQuery)), // Needs an initial value so that the combineLatest emits before anything is typed
			this.searchSuggestionService.state$.pipe(map((state) => state.topProductSuggestions ?? [])),
		])
			.pipe(
				takeUntil(this.destroyed$),
				distinctUntilChanged(([focusA, valueA], [focusB, valueB]) => focusA === focusB && valueA === valueB),
				filter(
					([focusEvent, valueChange, suggestions]) =>
						typeof focusEvent === 'string' && valueChange === '' && !!suggestions
				),
				filter(() => this.searchType === SearchType.Grocery),
				delay(300) // Animation duration of the search box
			)
			.subscribe(([, , suggestions]) => this.onSearchSuggestionFetchSuccess(suggestions, 'Popular items'));

		this.shopperService.addToTrolley$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
			this.blurButtonClick();
		});

		this.hasInputtedText$ = this.searchForm.valueChanges.pipe(
			map(({ search }) => !!search),
			tap((hasSearchValue) => {
				this.hasInputtedText = hasSearchValue;
			})
		);
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
    	this.destroyed$.complete();
	}

	// TODO: This stuff should be moved to a service within search.
	// Worth doing when we reconstruct the header.
	// Also this may cause issues with clearing search
	setSearchTermToMatchSearchQueryStringParam(search = ''): void {
		this.searchForm.patchValue({ search });

		this.searchParam = !!search;
	}

	clearButtonClick(ev: MouseEvent): void {
		if (!this.isMobile && ev.type === 'mousedown') {
			// on desktop we want to use the mousedown event to do the clearing since the clear button moves with the focus-within animation
			this.clearSearchSuggestions();
			this.searchForm.patchValue({ search: '' });
			this.searchParam = false;
			setTimeout(() => {
				this.searchInputElement.input.nativeElement.focus();
			});
			this.datalayerService.trackSearchEvent(this.searchType, 'clear');
		} else if (this.isMobile && ev.type === 'click') {
			// but on mobile where it doesn't move it's better to use the click event since the timeout can interfere with the soft keyboard being opened on focus (ios needs direct user interaction to open keyboard)
			this.clearSearchSuggestions();
			this.searchForm.patchValue({ search: '' });
			this.searchParam = false;
			this.searchInputElement.input.nativeElement.focus();
			this.datalayerService.trackSearchEvent(this.searchType, 'clear');
		}
	}

	blurButtonClick(): void {
		this.searchParam = false;
		this.clearSearchSuggestions();
		this.searchForm.patchValue({ search: '' });
	}

	clearSearchSuggestions(): void {
		this.searchSuggestions = [];
	}

	fetchSearchSuggestions(event: string): void {
		// cancel any pending requests
		if (this.suggestionsSubscription && !this.suggestionsSubscription.closed) {
			this.suggestionsSubscription.unsubscribe();
		}

		this.suggestionsSubscription = this.searchSuggestionService.fetchSearchSuggestions(
			event,
			this.searchForm.get('searchType')?.value
		);
	}

	handleSearchSuggestionSelection(event: { value: any }): void {
		this.datalayerService.trackSearchEvent(this.searchType, 'dropdown', event.value);
		this.tealiumService.link({
			...tealiumSearchTrackingData,
			nav_sub_type: this.searchType,
			nav_value: this.searchValue,
		});
		this.navigateToSearch(event.value);
	}

	handleSearchSuggestionFocus(event: any): void {
		this.focussedSuggestion = `${this.suggestionIdentifierPrefix}${event}`;
	}

	navigateToSearch(searchValue: string): void {
		// cancel any pending requests
		if (this.suggestionsSubscription && !this.suggestionsSubscription.closed) {
			this.suggestionsSubscription.unsubscribe();
		}
		this.autocomplete.unsubscribeToSearchSuggestions();
		this.searchInputElement.input.nativeElement.blur();

		// because the app uses async history to control suggestion visibility it's important to wait for that to finish before navigating, hence the timeout (upped the number from 600 to 1000 to try an aleviate slower android devices)
		const timeout = this.isEmbeddedInApp ? 1000 : 0;

		const path = this.searchType === SearchType.Grocery ? ['shop', 'searchproducts'] : ['recipes', 'search'];
		const queryParams = this.searchType === SearchType.Grocery ? { search: searchValue } : { q: searchValue };
		const urlTree = this.router.createUrlTree(path, { queryParams });

		setTimeout(() => {
			if (this.traderBaseUrl) {
				this.window.location.href = `${this.traderBaseUrl}${this.router.serializeUrl(urlTree)}`;
			} else {
				this.router.navigate(path, {
					queryParams,
				});
			}
		}, timeout);
	}

	onSearchSuggestionFetchSuccess = (result: string[], header = ''): void => {
		if (!Array.isArray(result)) {
			return;
		}

		this.searchSuggestions = result.map((r) => ({ label: r, value: r }));
		if (!result.length) {
			this.resultsHeader = 'No suggestions';
		} else {
			this.resultsHeader = header;
		}
		this.cdr.markForCheck();
	};

	onKeyDownEnter(event: any): void {
		if (event.key === 'Enter') {
			this.handleSubmitWithTrack('enter_keyboard');
		}
	}

	submitButtonClick(): void {
		this.handleSubmitWithTrack('magnifying_glass');
	}

	handleSubmitWithTrack(trackName: string): void {
		if (this.hasInputtedText) {
			this.datalayerService.trackSearchEvent(this.searchType, trackName);
			this.tealiumService.link({
				...tealiumSearchTrackingData,
				nav_sub_type: this.searchType,
				nav_value: this.searchValue,
			});
			this.navigateToSearch(this.searchValue);
		} else {
			this.searchInputElement?.input.nativeElement.focus();
		}
	}

	isInRecipesSection(url: string): null | object {
		return url.match(/^\/recipes/i);
	}

	subscribeToSearchSuggestionResponse(): void {
		this.searchSuggestionService.searchSuggestionResponse
			.pipe(takeUntil(this.destroyed$))
			.subscribe(this.onSearchSuggestionFetchSuccess);
	}

	scanToTrolley(): void {
		this.nativeBridgeService.broadcastNativeEvent(BridgeEvent.scanToTrolley, {});
	}
}
