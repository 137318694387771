import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { HOW_WHERE_WHEN_PATH } from '@woolworthsnz/shop';
import {
	BreakPointService,
	CustomWindow,
	Device,
	ButtonComponent,
	TRADER_BASE_URL,
	WINDOW,
	SvgIconComponent,
} from '@woolworthsnz/styleguide';
import { map } from 'rxjs/operators';
import { NgIf, AsyncPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FulfilmentState, FulfilmentStoreService } from '@woolworthsnz/fulfilment';
import { Observable } from 'rxjs';

enum HowWhereWhenTimeSlotJourneyState {
	NotSelected,
	Selected,
	Expired,
	Closed,
}

@Component({
	selector: 'global-nav-how-where-when-bar',
	templateUrl: './how-where-when-bar.component.html',
	styleUrls: ['./how-where-when-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, ButtonComponent, AsyncPipe, SvgIconComponent],
})
export class HowWhereWhenBarComponent {
	isMobileDevice = false;

	get informationMessage$(): Observable<string | undefined> {
		return this.fulfilmentStoreService.state$.pipe(
			map((state: FulfilmentState) => this.getMessage(state, 'information'))
		);
	}

	get actionMessage$(): Observable<string | undefined> {
		return this.fulfilmentStoreService.state$.pipe(
			map((state: FulfilmentState) => this.getMessage(state, 'action'))
		);
	}

	get iconName$(): Observable<string | undefined> {
		return this.fulfilmentStoreService.state$.pipe(map((state: FulfilmentState) => this.getIconName(state)));
	}

	constructor(
		private fulfilmentStoreService: FulfilmentStoreService,
		private router: Router,
		private breakPointService: BreakPointService,
		@Inject(WINDOW) private window: CustomWindow,
		@Optional() @Inject(TRADER_BASE_URL) private traderBaseUrl?: string
	) {
		this.breakPointService.device$.pipe(takeUntilDestroyed()).subscribe((device: Device) => {
			this.isMobileDevice = device === Device.MOBILE;
		});
	}

	handleActionButtonClick(): void {
		if (this.traderBaseUrl) {
			this.window.location.href = `${this.traderBaseUrl}/${HOW_WHERE_WHEN_PATH}`;
		} else {
			this.router.navigateByUrl(HOW_WHERE_WHEN_PATH);
		}
	}

	private getMessage(state: FulfilmentState, type: 'information' | 'action'): string {
		const journeyState = this.getTimeSlotJourneyState(state);
		let selectedDayName = '';

		if (state.selectedDate && journeyState === HowWhereWhenTimeSlotJourneyState.Selected) {
			const date = new Date(state.selectedDate);
			selectedDayName = this.getDayName(date);
		}

		const formatTime = (time: string | null | undefined): string =>
			time ? time.replace(/(AM|PM)/, (match) => match.toLowerCase()) : '';

		const informationMessages = {
			[HowWhereWhenTimeSlotJourneyState.NotSelected]: 'For accurate prices and stock information, ',
			[HowWhereWhenTimeSlotJourneyState.Selected]: `Chosen time: ${selectedDayName}, ${formatTime(state.startTime)} to ${formatTime(state.endTime)}. `,
			[HowWhereWhenTimeSlotJourneyState.Expired]:
				'Your time slot expired. To see accurate prices and stock information, ',
			[HowWhereWhenTimeSlotJourneyState.Closed]:
				'Your time slot closed. To see accurate prices and stock information, ',
		};

		const actionMessages = {
			[HowWhereWhenTimeSlotJourneyState.NotSelected]: 'choose a location and time slot',
			[HowWhereWhenTimeSlotJourneyState.Selected]: 'Change time slot',
			[HowWhereWhenTimeSlotJourneyState.Expired]: 'choose a time slot',
			[HowWhereWhenTimeSlotJourneyState.Closed]: 'choose a time slot',
		};

		const messages = type === 'information' ? informationMessages : actionMessages;

		return messages[journeyState] || '';
	}

	private getIconName(state: FulfilmentState): string {
		const journeyState = this.getTimeSlotJourneyState(state);

		const iconNames = {
			[HowWhereWhenTimeSlotJourneyState.NotSelected]: 'location-pin',
			[HowWhereWhenTimeSlotJourneyState.Selected]: 'wall-clock',
			[HowWhereWhenTimeSlotJourneyState.Expired]: 'alert',
			[HowWhereWhenTimeSlotJourneyState.Closed]: 'alert',
		};

		return iconNames[journeyState] || 'alert';
	}

	private getDayName(date: Date): string {
		const today = new Date();

		return date.toDateString() === today.toDateString()
			? 'Today'
			: date.toLocaleDateString('en-US', { weekday: 'long' });
	}

	private getTimeSlotJourneyState(state: FulfilmentState): HowWhereWhenTimeSlotJourneyState {
		const hasTimeSelection = !!state.startTime?.trim() && !!state.endTime?.trim();
		const hasTimeslotExpired = !!state.currentReservationAlertsFired?.expired;
		const hasTimeslotClosed = !!state.currentReservationAlertsFired?.closed;

		if (hasTimeslotClosed) {
			return HowWhereWhenTimeSlotJourneyState.Closed;
		} else if (hasTimeslotExpired) {
			return HowWhereWhenTimeSlotJourneyState.Expired;
		} else if (hasTimeSelection) {
			return HowWhereWhenTimeSlotJourneyState.Selected;
		}
		return HowWhereWhenTimeSlotJourneyState.NotSelected;
	}
}
