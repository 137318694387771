// testing npm install
import { transition, trigger } from '@angular/animations';
import {
	AsyncPipe,
	isPlatformBrowser,
	isPlatformServer,
	JsonPipe,
	NgClass,
	NgFor,
	NgIf,
	NgTemplateOutlet,
} from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Optional,
	Output,
	PLATFORM_ID,
	QueryList,
	TrackByFunction,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { FulfilmentState, FulfilmentStoreService } from '@woolworthsnz/fulfilment';
import { ShellService, ShellState } from '@woolworthsnz/shop';
import {
	AppSettingsService,
	BreakPointService,
	ButtonComponent,
	Device,
	fadeInSteps,
	fadeOutSteps,
	FeatureService,
	isLinkAbsolute,
	MaybeExternalLinkDirective,
	NavigationItemGroupWithAnalytics,
	NavigationMenuItemWithAnalytics,
	NotificationType,
	ScrollBlockDirective,
	ShopperService,
	ShopperState,
	SvgIconComponent,
	TrackEventDirective,
	TrackingEvent,
	TRADER_BASE_URL,
} from '@woolworthsnz/styleguide';
import { EdrBalanceResponseV2, NavigationMenuItem } from '@woolworthsnz/trader-api';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { NavItemsService } from '../../services/nav-items.service';
import { BrowseMenuComponent } from '../browse-menu/browse-menu.component';
import { LinksComponent } from '../links/links.component';
import { FamilyBarComponent } from '../family-bar/family-bar.component';
import { FooterLinksComponent } from '../footer-links/footer-links.component';
import { SignInComponent } from '../sign-in/sign-in.component';
import { MyAccountComponent } from '../my-account/my-account.component';
import {
	EDRRewardsHeaderComponent,
	EverydayRewardsFacade,
	PointsBalanceHeaderType,
} from '@woolworthsnz/everyday-rewards';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'global-nav-mobile-nav',
	templateUrl: './mobile-nav.component.html',
	styleUrls: ['./mobile-nav.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('maskFade', [transition(':enter', [...fadeInSteps]), transition(':leave', [...fadeOutSteps])]),
	],
	standalone: true,
	imports: [
		NgClass,
		NgIf,
		NgTemplateOutlet,
		TrackEventDirective,
		MyAccountComponent,
		SignInComponent,
		NgFor,
		ButtonComponent,
		SvgIconComponent,
		MaybeExternalLinkDirective,
		FooterLinksComponent,
		FamilyBarComponent,
		LinksComponent,
		BrowseMenuComponent,
		ScrollBlockDirective,
		AsyncPipe,
		EDRRewardsHeaderComponent,
		JsonPipe,
	],
})
export class MobileNavComponent implements OnInit {
	@ViewChild('nav') nav: ElementRef<HTMLElement>;
	@ViewChild('browseMenu') browseMenu: BrowseMenuComponent;
	@ViewChildren('navButton') navList: QueryList<ButtonComponent>;

	@Input() public show: Observable<boolean>;
	@Input() public mobileNav: any;
	@Input() public mainNav: any;
	@Input() public externalLinksInCurrentTab = false;

	@Output() public logout = new EventEmitter();

	public shellState: ShellState;

	public fulfilmentState: FulfilmentState;
	public shopperState: ShopperState;
	public edrBalance$: Observable<EdrBalanceResponseV2 | undefined>;
	public subnavItems = [];
	public filteredNavItemsShownInBrowse$: Observable<NavigationMenuItemWithAnalytics[]>;
	public browseNavItem$: Observable<NavigationMenuItemWithAnalytics | undefined>;
	public mainNavs$: Observable<NavigationMenuItemWithAnalytics[]>;

	public subNavVisible$ = new BehaviorSubject(false);
	combinedState$ = combineLatest([
		this.shellService.state$,
		this.fulfilmentStoreService.state$,
		this.shopperService.state$,
	]).pipe(takeUntilDestroyed());
	device$ = this.breakpointService.device$.pipe(takeUntilDestroyed());

	public links: any[] = [];

	public trackingEvent = TrackingEvent;
	public notificationType = NotificationType;
	public quickNavLinks: {
		url: string;
		text: string;
	}[] = [];
	public hasPastOrders: boolean;
	public isLoggedIn$: Observable<boolean | undefined>;
	public firstName$: Observable<string | undefined>;
	public edrBalanceHeaderType = PointsBalanceHeaderType;

	private navAnimationDuration = 600;
	private _selectedItem: string;

	constructor(
		private cdr: ChangeDetectorRef,
		private fulfilmentStoreService: FulfilmentStoreService,
		private appSettingsService: AppSettingsService,
		private shellService: ShellService,
		private shopperService: ShopperService,
		private breakpointService: BreakPointService,
		private featureService: FeatureService,
		private navItemsService: NavItemsService,
		private edrFacade: EverydayRewardsFacade,
		@Inject(PLATFORM_ID) private platformId: Object,
		@Optional() @Inject(TRADER_BASE_URL) private baseDomain: string
	) {
		if (isPlatformServer(this.platformId)) {
			this.navAnimationDuration = 0;
		}
		this.quickNavLinks = this.appSettingsService.getSetting('navs')?.quickNav || [];

		this.isLoggedIn$ = this.shopperService.select('isLoggedIn');
		this.firstName$ = this.shopperService.select('firstName');

		this.mainNavs$ = this.navItemsService.navItems$;
		this.browseNavItem$ = this.navItemsService.browseNavItem$;
		this.filteredNavItemsShownInBrowse$ = this.navItemsService.filteredNavItemsShownInBrowse$;
	}

	get methodIsCourier(): boolean {
		return this.fulfilmentState && this.fulfilmentState.method === 'Courier';
	}

	get timeslotRoute(): string {
		return this.shopperState.isShopper ? '/shop/schedulereserve' : '/shop/securelogin';
	}

	get selectedItem(): string {
		return this._selectedItem.toLowerCase();
	}

	get isBrowseNavItem(): boolean {
		return this._selectedItem?.toLowerCase() === 'browse';
	}

	get selectedItemText(): string {
		return this._selectedItem;
	}

	set selectedItem(itemText: string) {
		this._selectedItem = itemText.toLowerCase() === 'recipes' ? 'recipecategory' : itemText;
	}

	public ngOnInit(): void {
		this.combinedState$.subscribe(([shellState, fulfilmentState, shopperState]) => {
			this.shellState = shellState;
			this.fulfilmentState = fulfilmentState;
			this.shopperState = shopperState;
			this.hasPastOrders = parseInt(this.shopperState.orderCount ?? '0', 10) > 0;
			if (this.mainNav) {
				this.buildMobileNavigation();
			}
		});

		if (isPlatformBrowser(this.platformId)) {
			this.device$
				.pipe(
					filter(
						(device) => device === Device.DESKTOP || device === Device.LARGE || device === Device.XLARGE
					),
					tap(() => this.hideMobileNav())
				)
				.subscribe();
		}

		this.edrBalance$ = this.edrFacade.edrBalance$;
	}

	trackByFn: TrackByFunction<NavigationMenuItemWithAnalytics> = (_: any, item: NavigationMenuItemWithAnalytics) =>
		item.label;

	isURLAbsolute(url: string): boolean {
		return isLinkAbsolute(url);
	}

	addDeliveryDetailsLink = (): {
		url: string;
		text: string;
	} => ({
		url: '/shop/deliverydetails',
		text: `My ${this.methodIsCourier ? 'Delivery' : this.fulfilmentState.method}`,
	});

	getDynamicHeaderLink = ():
		| boolean
		| undefined
		| {
				url: string | undefined;
				text: string | undefined;
		  } =>
		this.shellState?.dynamicHeaderLink?.isEnabled && {
			url: this.shellState.dynamicHeaderLink.url,
			text: this.shellState.dynamicHeaderLink.label,
		};

	buildMobileNavigation(): void {
		let mainNavNoHomeLink = [...this.mainNav];
		mainNavNoHomeLink.shift();

		mainNavNoHomeLink = this.removeSpecialLinkIfSpecialsAreDisabled(mainNavNoHomeLink);
		this.links = [
			...mainNavNoHomeLink,
			this.getDynamicHeaderLink(),
			this.addDeliveryDetailsLink(),
			...this.mobileNav,
		];
		this.links = this.links.filter((l) => l !== undefined);

		this.cdr.markForCheck();
	}

	clearSubnavItems = (): never[] => (this.subnavItems = []);

	getItemNameIfRecipes = (name: string): string | boolean => this.selectedItem === 'recipecategory' && name;

	getSubnavItemLink = (item: any): string => {
		if (this.selectedItem === 'recipecategory') {
			return `/shop/${this.selectedItem}/${item.id}`;
		}

		return `/shop/${this.selectedItem}/${item.url}`;
	};

	handleSubnavBack(): void {
		this.subNavVisible$.next(false);
		// Don't clear the subnav until it has animated out
		setTimeout(() => {
			this.clearSubnavItems();
			this.cdr.markForCheck();
		}, this.navAnimationDuration);
	}

	handleLogout(): void {
		this.handleSubnavBack();
		this.logout.emit();
	}

	hideMobileNav(): void {
		this.shellService.toggleMobileNav('hide');

		setTimeout(() => {
			this.nav.nativeElement.scrollTop = 0;
			if (this.browseMenu) {
				this.browseMenu.resetData();
			}
			this.subNavVisible$.next(false);
			this.clearSubnavItems();
			this.cdr.markForCheck();
		}, this.navAnimationDuration);
	}

	removeSpecialLinkIfSpecialsAreDisabled = (mainNav: any): any[] =>
		this.appSettingsService.getSetting('showSpecials')
			? mainNav
			: mainNav.filter((item: any) => item.text.toLowerCase() !== 'specials');

	showSubnav(item: string, navigationItems?: NavigationItemGroupWithAnalytics[]): void {
		this.selectedItem = item;
		this.subnavItems = navigationItems ?? this.shellState[item.toLowerCase()];
		this.subNavVisible$.next(true);
	}

	getLinkGroups(
		subnavItems: {
			id: number;
			label: string;
			url: string;
		}[]
	): NavigationItemGroupWithAnalytics[] {
		return [
			{
				label: this.selectedItemText,
				items: [...subnavItems]
					.sort((a, b) => a.id - b.id)
					.map((item) => ({
						...item,
						url: this.getSubnavItemLink(item),
					})),
			},
		];
	}

	handleTrackEvent(item: NavigationMenuItem): {
		event: TrackingEvent;
		type: NotificationType;
		name: string | undefined;
	} | null {
		// If it has sub-menu items, don't track any
		if (item.navigationItems?.length) {
			return null;
		}

		return {
			event: this.trackingEvent.NotificationEvent,
			type: this.notificationType.Global,
			name: item.label,
		};
	}
}
