<div data-testid="featureRecipeStamp" *ngIf="enableRecipeStamp$ | async">
    <div *ngIf="{
            aislesNavItems: (aislesNavItems$ | async)?.[0].label, 
            shelvesNavItems: (shelvesNavItems$ | async)?.[0].label,
            flagVariation:  flagVariation$ | async
        } as navItem"
        (click)="onPromoTileClicked(navItem.aislesNavItems, navItem.shelvesNavItems, navItem.flagVariation)"
    >
        <div data-testid="smallMacroRecipe" 
             *ngIf="(navItem.flagVariation === variants.V1 && aisles.indexOf(navItem.aislesNavItems) >= 0 && !navItem.shelvesNavItems) ||
                    (navItem.flagVariation === variants.V2 && aisles.indexOf(navItem.aislesNavItems) >= 0 && shelves.indexOf(navItem.shelvesNavItems) >= 0)">
            <a
                role="navigation"
                aria-label="macro recipe link"
                href="/recipes/dinner/3288/honey-and-soy-tofu-stir-fry"
            >
                <img
                    [width]="width"
                    src="/images/global-nav/mega-menu-recipe-stamp/small-macro-recipe.jpg"
                    alt="small macro recipe"
                    loading="lazy"
                />
            </a>
        </div>
        <div data-testid="largeMacroRecipe"
             *ngIf="(navItem.flagVariation === variants.V3 && aisles.indexOf(navItem.aislesNavItems) >= 0 && !navItem.shelvesNavItems) ||
                    (navItem.flagVariation === variants.V4 && aisles.indexOf(navItem.aislesNavItems) >= 0 && shelves.indexOf(navItem.shelvesNavItems) >= 0)">
            <a
                role="navigation"
                aria-label="macro recipe link"
                href="/recipes/dinner/3288/honey-and-soy-tofu-stir-fry"
            >
                <img
                    [width]="width"
                    src="/images/global-nav/mega-menu-recipe-stamp/large-macro-recipe.jpg"
                    alt="macro recipe link"
                    loading="lazy"
                />
            </a>
        </div>
    </div>
</div>
