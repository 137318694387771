<global-nav-links [linkGroups]="linkGroups$ | async" [orientation]="orientation" itemSpacing="dense"></global-nav-links>
<div class="footer-endColumn">
	<div *ngIf="orientation === 'horizontal'">
		<h4 i18n="@@footer-storeLinksTitle">{{ storeLinksTitle$ | async }}</h4>
		<div class="storeLinks">
			<a
				class="storeLinks-apple"
				href="https://itunes.apple.com/nz/app/countdown-pick-up/id1278164689?mt=8"
				target="_blank"
			>
				<img
					height="33"
					width="96"
					src="/images/global-nav/footer/download-on-the-app-store-en-us.svg"
					alt="Download on the App Store"
				/>
			</a>
			<a
				class="storeLinks-google"
				href="https://play.google.com/store/apps/details?id=nz.co.countdown.android.pickup&hl=en"
				target="_blank"
			>
				<img
					alt="Get it on Google Play"
					width="129"
					height="50"
					src="/images/global-nav/footer/en_badge_web_generic.png"
				/>
			</a>
		</div>
	</div>
	<div>
		<h4>We're social...</h4>
		<global-nav-socials class="text-right"></global-nav-socials>
	</div>
</div>
