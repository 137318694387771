export enum KeyValue {
	ArrowLeft = 'ArrowLeft',
	ArrowRight = 'ArrowRight',
	ArrowUp = 'ArrowUp',
	ArrowDown = 'ArrowDown',
	Home = 'Home',
	End = 'End',
	Enter = 'Enter',
	Space = ' ',
}
