<nav>
	<ul>
		<li>
			<a
				[href]="edrLandingPageUrl"
				i18n="@@FamilyBar-EverydayRewards"
				[cdxTrackEvent]="getTrackingData('everyday-rewards')"
			>
				<span *ngIf="!isSmallScreen"
					><img alt="Everyday Rewards" src="/logos/footer/everyday-rewards.svg" width="74" height="27"
				/></span>
				<span *ngIf="isSmallScreen">Everyday Rewards</span></a
			>
		</li>
		<li>
			<a
				href="//bunch.countdown.co.nz"
				i18n="@@FamilyBar-Bunch"
				target="_blank"
				[cdxTrackEvent]="getTrackingData('bunch')"
			>
				<span *ngIf="!isSmallScreen"
					><img alt="Bunch" src="/logos/footer/bunch.png" width="100" height="30"
				/></span>
				<span *ngIf="isSmallScreen">Bunch</span></a
			>
		</li>
		<li>
			<a
				href="//vineonline.co.nz"
				i18n="@@FamilyBar-VineOnline"
				target="_blank"
				[cdxTrackEvent]="getTrackingData('vine_online')"
			>
				<span *ngIf="!isSmallScreen"
					><img alt="Vine" src="/logos/footer/vineonline.svg" width="54" height="26"
				/></span>
				<span *ngIf="isSmallScreen">Vineonline</span></a
			>
		</li>
		<li>
			<a
				[href]="nzGroceryWholesalersLink"
				i18n="@@FamilyBar-NZGroceryWholesalers"
				target="_blank"
				[cdxTrackEvent]="getTrackingData('nz_grocery_wholesalers')"
			>
				<span *ngIf="!isSmallScreen"
					><img
						alt="NZ Grocery Wholesalers"
						src="/logos/footer/nz-grocery-wholesalers.svg"
						width="125"
						height="35"
				/></span>
				<span *ngIf="isSmallScreen">NZ Grocery Wholesalers</span></a
			>
		</li>
	</ul>
</nav>
