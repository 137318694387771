<ng-container
	*ngIf="{
		variationKey: navExperiment7VariationKey$ | async,
		isVariantActive: isNavExperiment7VariationActive$ | async,
	} as experiment"
>
	<ng-container *ngIf="!!experiment.variationKey">
		<global-nav-family-bar
			*ngIf="!simplified && experiment.isVariantActive && !isEmbeddedInApp"
			[ngClass]="{
				'dark-theme': darkTheme,
				v1_a: experiment.variationKey === 'v1_a',
				v1_b: experiment.variationKey === 'v1_b',
				v1_c: experiment.variationKey === 'v1_c',
			}"
			(logout)="handleLogout()"
		>
		</global-nav-family-bar>

		<div class="non-mobile-nav" [ngClass]="{ 'global-embedded-nav': isEmbeddedInApp }">
			<div *ngIf="experiment.isVariantActive" class="navExperiment-whitespace"></div>
			<div
				class="globalnav"
				[ngClass]="{
					'dark-theme': darkTheme,
					'globalnav--shadow': simplified,
					'main-nav-menu-active': shouldShowUnderlay,
				}"
				*cdxEmbedded
			>
				<global-nav-header
					menuIcon="menu-rounded"
					align="left"
					[iconFill]="darkTheme ? 'light' : 'dark'"
					(openMobileNav)="openMobileNav()"
					[class.v1_c]="experiment.variationKey === 'v1_c'"
				>
					<a
						desktop-image
						maybeExternal
						[maybeExternalCurrentTab]="externalLinksInCurrentTab"
						[href]="baseLink"
						[cdxTrackEvent]="{
							event: trackingEvent.NotificationEvent,
							type: notificationType.Global,
							name: 'shop_logo',
						}"
						class="site-link"
						><img
							*ngIf="!darkTheme; else darkThemeTemplate"
							alt="Online Supermarket: Online Grocery Shopping &amp; Free Recipes at woolworths.co.nz"
							class="site-logo"
							[height]="(desktopLogo$ | async)?.height"
							[src]="(desktopLogo$ | async)?.url"
						/>
						<ng-template #darkThemeTemplate>
							<ng-container *ngIf="deliveryNowSelected; else expressHeader">
							  <img
								alt="Online Supermarket: Online Grocery Shopping &amp; Free Recipes at woolworths.co.nz"
								class="site-logo"
								height="70"
								src="/images/global-nav/header/deliverynow-logo.svg"
								width="207"
							  />
							</ng-container>
						</ng-template>
						<ng-template #expressHeader>
							<img
								alt="Online Supermarket: Online Grocery Shopping &amp; Free Recipes at woolworths.co.nz"
								class="site-logo"
								height="70"
								src="/images/global-nav/header/express-logo.svg"
								width="207"
						/></ng-template>
					</a>

					<a
						mobile-image
						routerLink="/"
						[cdxTrackEvent]="{
							event: trackingEvent.NotificationEvent,
							type: notificationType.Global,
							name: 'shop_logo',
						}"
						><img
							*ngIf="!darkTheme; else expressMobileLogo"
							alt="Online Supermarket: Online Grocery Shopping &amp; Free Recipes at woolworths.co.nz"
							height="40"
							[src]="mobileLogoURL$ | async"
							width="40"
						/>

						<ng-template #expressMobileLogo>
							<img
								alt="Online Supermarket: Online Grocery Shopping &amp; Free Recipes at woolworths.co.nz"
								height="40"
								src="/images/global-nav/header/express-logo-wapple.svg"
						/></ng-template>
					</a>
					<div
						*ngIf="experiment.isVariantActive && !simplified"
						desktop-start-slot
						class="navExperimentV1-header"
					>
						<global-nav-main-nav
							[ngClass]="{ 'global-embedded-nav': isEmbeddedInApp }"
							[isNavExperimentV1Desktop]="experiment.isVariantActive"
							(menuOpen)="handleMenuChange($event)"
						>
						</global-nav-main-nav>

						<div class="search-container" *ngIf="!(isMobileDevice$ | async)">
							<ng-container *ngTemplateOutlet="search"></ng-container>
						</div>
					</div>

					<global-nav-basket-totals
						mobile-end-slot
						*ngIf="!simplified"
						@fadeInOut
						[amountToPay]="amountToPay$ | async"
						[trolleyLink]="trolleyLink"
						[totalItems]="totalItems$ | async"
						[darkTheme]="darkTheme"
					></global-nav-basket-totals>
					<ng-container tablet-end-slot *ngIf="!simplified">
						<fulfilment-bar
							[externalLinksInCurrentTab]="externalLinksInCurrentTab"
							[disabled]="isChangingOrder$ | async"
							[timeslotRoute]="timeslotRoute"
							@fadeInOut
						></fulfilment-bar>
						<global-nav-basket-totals
							@fadeInOut
							[amountToPay]="amountToPay$ | async"
							[trolleyLink]="trolleyLink"
							[totalItems]="totalItems$ | async"
							[darkTheme]="darkTheme"
						></global-nav-basket-totals>
					</ng-container>
					<ng-container desktop-end-slot>
						<section class="globalnav-quickNav">
							<global-nav-quick-nav
								(logout)="handleLogout()"
								*ngIf="!simplified && !experiment.isVariantActive"
								@fadeInOut
								[externalLinksInCurrentTab]="externalLinksInCurrentTab"
							></global-nav-quick-nav>
							<ng-container>
								<!-- TODO: https://woolworths-agile.atlassian.net/browse/CLSE-2807 replace points balance calc with new field -->
								<edr-rewards-header
									*ngIf="simplified && (edrBalance$ | async) as edrBalance"
									[totalPointsRequired]="edrBalance.pointsPerReward"
									[rewardsAvailable]="edrBalance.pointsAsVoucherDollars"
									[pointsBalance]="edrBalance.pointsPerReward - edrBalance.pointsUntilNextReward"
									[redemptionPreference]="edrBalance.redemptionPreference"
									[externalLinksInCurrentTab]="externalLinksInCurrentTab"
									[headerType]="pointsBalanceHeaderType.DesktopHeader"
									[backgroundColor]="darkTheme ? 'white' : 'transparent'"
								/>
							</ng-container>
						</section>
					</ng-container>
				</global-nav-header>
				<div *ngIf="experiment.isVariantActive && !(isMobileDevice$ | async)" #subnav></div>
				<global-nav-change-order-bar
					*ngIf="!!(isChangingOrder$ | async) && !!experiment.isVariantActive && !(isMobileDevice$ | async)"
				></global-nav-change-order-bar>
			</div>

			<div
				class="globalnav-midnav"
				*ngIf="!simplified"
				@slideUpDown
				[ngClass]="{ 'globalnav-midnav--embedded': isEmbeddedInApp, 'dark-theme': darkTheme }"
			>
				<fulfilment-bar
					[externalLinksInCurrentTab]="externalLinksInCurrentTab"
					[disabled]="isChangingOrder$ | async"
					[timeslotRoute]="timeslotRoute"
				></fulfilment-bar>
			</div>

			<div
				*ngIf="experiment.isVariantActive && !simplified && ((isMobileDevice$ | async) || isEmbeddedInApp)"
				class="globalnav-subnav"
				[ngClass]="{ 'globalnav-subnav--embedded': isEmbeddedInApp, 'dark-theme': darkTheme }"
				#subnav
			>
				<div class="navExperimentV1-subnav">
					<ng-container *ngTemplateOutlet="search"></ng-container>

					<global-nav-main-nav
						[ngClass]="{ 'global-embedded-nav': isEmbeddedInApp }"
						(menuOpen)="handleMenuChange($event)"
					>
					</global-nav-main-nav>

					<ng-container *ngIf="isEmbeddedInApp">
						<fulfilment-bar
							[externalLinksInCurrentTab]="externalLinksInCurrentTab"
							[disabled]="isChangingOrder$ | async"
							[timeslotRoute]="timeslotRoute"
						></fulfilment-bar>
						<global-nav-basket-totals
							[amountToPay]="amountToPay$ | async"
							[trolleyLink]="trolleyLink"
							[totalItems]="totalItems$ | async"
							[darkTheme]="darkTheme"
						></global-nav-basket-totals>
					</ng-container>
				</div>
				<global-nav-change-order-bar *ngIf="isChangingOrder$ | async"></global-nav-change-order-bar>				
			</div>

			<div *ngIf="experiment.isVariantActive" class="navExperiment-pre-whitespace"></div>
			<div
				*ngIf="experiment.isVariantActive && !(isChangingOrder$ | async)"
				class="navExperiment-whitespace"
			></div>

			<div
				*ngIf="!experiment.isVariantActive"
				class="globalnav-subnav"
				[ngClass]="{ 'globalnav-subnav--embedded': isEmbeddedInApp, 'dark-theme': darkTheme }"
				#subnav
			>
				<div *ngIf="!simplified" @slideUpDown [ngClass]="{ 'main-nav-menu-active': shouldShowUnderlay }">
					<ng-container *ngIf="isSmallScreen$ | async">
						<ng-container *ngTemplateOutlet="search; context: { type: 'shrunk' }"></ng-container>
					</ng-container>

					<global-nav-main-nav
						[ngClass]="{ 'global-embedded-nav': isEmbeddedInApp }"
						(menuOpen)="handleMenuChange($event)"
					></global-nav-main-nav>

					<ng-container *ngIf="(isSmallScreen$ | async) === false">
						<ng-container *ngTemplateOutlet="search"></ng-container>
					</ng-container>

					<fulfilment-bar
						[externalLinksInCurrentTab]="externalLinksInCurrentTab"
						[disabled]="isChangingOrder$ | async"
						[timeslotRoute]="timeslotRoute"
					></fulfilment-bar>

					<global-nav-basket-totals
						[amountToPay]="amountToPay$ | async"
						[trolleyLink]="trolleyLink"
						[totalItems]="totalItems$ | async"
						[darkTheme]="darkTheme"
					></global-nav-basket-totals>
				</div>
				<global-nav-change-order-bar *ngIf="isChangingOrder$ | async"></global-nav-change-order-bar>
				<global-nav-how-where-when-bar *ngIf="!(isChangingOrder$ | async) && (enableHowWhereWhenBar$ | async)"></global-nav-how-where-when-bar>				
			</div>

			<ng-container *ngIf="isSmallScreen$ | async">
				<global-nav-mobile-nav
					[mainNav]="mainNav"
					[mobileNav]="mobileNav"
					[externalLinksInCurrentTab]="externalLinksInCurrentTab"
					(logout)="handleLogout()"
					*cdxEmbedded="'hide'"
				></global-nav-mobile-nav>
				<global-nav-browse-nav *cdxEmbedded="'hide'"></global-nav-browse-nav>
			</ng-container>
		</div>

		<div class="globalnav-underlay" [ngStyle]="{ display: shouldShowUnderlay ? 'block' : 'none' }"></div>

		<ng-template #search let-type="type">
			<global-nav-search
				data-cy="search-bar-panel"
				[subnav]="subnav"
				[ngClass]="{
					'globalnav-search--embedded': isEmbeddedInApp,
					'globalnav-search--shrunk': type === 'shrunk',
				}"
			></global-nav-search>
		</ng-template>
		<ng-template cdkPortal #edrCelebratoryModal="cdkPortal">
			<edr-fireworks />
			<edr-celebratory-modal />
		</ng-template>
	</ng-container>
</ng-container>
