export const tealiumUtagBrowseMenu = {
	event: 'select_content',
	tealium_event: 'select_content',
	content_type: 'Browse menu',
};

export const tealiumUtagBrowseMenuDepartment = {
	...tealiumUtagBrowseMenu,
	browse_category_name: 'Department',
	browse_category_level: 'Level 1',
};

export const tealiumUtagBrowseMenuAisle = {
	...tealiumUtagBrowseMenu,
	browse_category_name: 'Aisle',
	browse_category_level: 'Level 2',
};

export const tealiumUtagBrowseMenuShelf = {
	...tealiumUtagBrowseMenu,
	browse_category_name: 'Shelf',
	browse_category_level: 'Level 3',
};

export const tealiumUtagBrowseMenuPromoTile = {
	event: 'select_promotion',
	tealium_event: 'select_promotion',
};
