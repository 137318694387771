<nav
	class="olynav-drawer nav-drawer-global"
	[ngClass]="{
		'olynav-shown': shellState.showMobileNav,
	}"
	#nav
>
	<ul
		class="olynav-level olynav-root"
		[ngClass]="{ 'olynav-root--subNavVisible': subNavVisible$ | async }"
		*ngIf="{
			isLoggedIn: isLoggedIn$ | async,
		} as featureEnabled"
	>
		<li
			class="olynav-item olynav-accountArea olynav-item--lightBackground"
			[ngClass]="{ isLoggedIn: featureEnabled.isLoggedIn }"
		>
			<ng-container *ngIf="featureEnabled.isLoggedIn; else loggedOut">
				<div class="sign-in-status">
					<h4>Kia ora, {{ firstName$ | async }}</h4>
					<a
						[cdxTrackEvent]="{
							event: trackingEvent.NotificationEvent,
							type: notificationType.Global,
							name: 'account',
							value: 'Sign Out',
						}"
						(click)="handleLogout()"
						href="javascript:void(0);"
						i18n="@@mobile-nav-signOut"
					>
						Sign Out
					</a>
				</div>
				<global-nav-my-account
					[refresh]="shellState.showMobileNav"
					fill="dark"
					(signOut)="handleLogout()"
					(navItemClicked)="hideMobileNav()"
				></global-nav-my-account>
			</ng-container>
			<ng-template #loggedOut>
				<global-nav-sign-in></global-nav-sign-in>
			</ng-template>
		</li>

		<li
			*ngIf="featureEnabled.isLoggedIn"
			class="olynav-item olynav-onecardArea olynav-onecardArea--changeOrderEnabled"
		>
			<!-- TODO: https://woolworths-agile.atlassian.net/browse/CLSE-2807 replace points balance calc with new field -->
			<edr-rewards-header
				*ngIf="edrBalance$ | async as edrBalance"
				[totalPointsRequired]="edrBalance.pointsPerReward"
				[rewardsAvailable]="edrBalance.pointsAsVoucherDollars"
				[pointsBalance]="edrBalance.pointsPerReward - edrBalance.pointsUntilNextReward"
				[redemptionPreference]="edrBalance.redemptionPreference"
				[externalLinksInCurrentTab]="externalLinksInCurrentTab"
				[headerType]="edrBalanceHeaderType.GlobalNavigation"
			/>
		</li>

		<ng-container *ngIf="mainNavs$ | async">
			<li *ngFor="let link of mainNavs$ | async" class="olynav-item">
				<button
					*ngIf="link.navigationItems; else globalNavAnchor"
					class="olynav-mainNav"
					cdxButton
					fillStyle="secondaryFlat"
					[fullWidth]="true"
					[inline]="true"
					endSlotSize="small"
					size="default"
					[endSlotActive]="true"
					[startSlotActive]="!!link.icon"
					startSlotSize="small"
					(click)="showSubnav(link.label, link.navigationItems)"
				>
					<cdx-svg-icon fill="dark" [name]="link.icon" size="small" start></cdx-svg-icon>
					{{ link.label }}
					<cdx-svg-icon name="chevron-right" fill="dark" size="small" end></cdx-svg-icon>
				</button>
				<ng-template #globalNavAnchor>
					<a
						cdxButton
						fillStyle="secondaryFlat"
						[fullWidth]="true"
						class="olynav-mainNav"
						size="default"
						[href]="link.url"
						maybeExternal
						[maybeExternalCurrentTab]="externalLinksInCurrentTab"
						[cdxTrackEvent]="{
							event: trackingEvent.NotificationEvent,
							type: notificationType.Global,
							name: link.label,
						}"
					>
						{{ link.label }}
					</a>
				</ng-template>
			</li>
		</ng-container>
		<ng-container *ngIf="quickNavLinks?.length">
			<li>
				<hr />
			</li>
			<li *ngFor="let quickNavLink of quickNavLinks" class="olynav-item">
				<a
					cdxButton
					fillStyle="secondaryFlat"
					[fullWidth]="true"
					class="olynav-mainNav"
					size="default"
					[href]="quickNavLink.url"
					maybeExternal
					[maybeExternalCurrentTab]="externalLinksInCurrentTab"
					[cdxTrackEvent]="{
						event: trackingEvent.NotificationEvent,
						type: notificationType.Global,
						name: quickNavLink.text,
					}"
				>
					{{ quickNavLink.text }}
				</a>
			</li>
		</ng-container>
		<li>
			<hr />
		</li>
		<li class="olynav-item">
			<global-nav-footer-links orientation="vertical"></global-nav-footer-links>
		</li>
		<li class="olynav-item olynav-item--lightBackground olynav-item--padded">
			<h4 i18n="@@mobileNav-familyBarTitle">You may also like</h4>
			<global-nav-family-bar></global-nav-family-bar>
		</li>
	</ul>

	<! -- SUB NAVS -->
	<div
		*ngIf="!isBrowseNavItem"
		class="olynav-level olynav-level--full-height olynav-level--initially-hidden"
		[ngClass]="{ 'olynav-level--visible': subNavVisible$ | async }"
	>
		<button
			cdxButton
			fillStyle="primaryInverse"
			fullWidth="true"
			class="olynav-link"
			startSlotSize="small"
			[size]="'default'"
			[startSlotActive]="true"
			(click)="handleSubnavBack()"
		>
			<cdx-svg-icon name="chevron-left" fill="green" size="small" start></cdx-svg-icon>
			Back
		</button>
		<global-nav-links
			orientation="vertical"
			[linkGroups]="subnavItems"
			fill="dark"
			[trackingName]="this.selectedItemText"
			(navItemClicked)="hideMobileNav()"
		></global-nav-links>
	</div>
	<div
		*ngIf="isBrowseNavItem"
		class="olynav-level olynav-level--full-height olynav-level--initially-hidden browse-menu"
		[ngClass]="{ 'olynav-level--visible': subNavVisible$ | async }"
	>
		<global-nav-browse-menu
			*ngIf="browseNavItem$ | async; let browseNavItem"
			#browseMenu
			[isDesktopNav]="false"
			[showTitle]="false"
			[browseNavItems]="browseNavItem.navigationItems"
			[otherNavItems]="(filteredNavItemsShownInBrowse$ | async) ?? []"
			[trackingName]="selectedItem"
			(backButtonClicked)="handleSubnavBack()"
			(closeButtonClicked)="hideMobileNav()"
		></global-nav-browse-menu>
	</div>
</nav>

<div cdxScrollBlock [@maskFade] class="olynav-mask" *ngIf="shellState.showMobileNav" (click)="hideMobileNav()"></div>
