import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalNavSettings } from './global-nav.settings.interface';

const defaultSettings: GlobalNavSettings = {
	externalLinksInCurrentTab: false,
	disableNewNavItemIndication: false,
};

@Injectable({
	providedIn: 'any',
})
export class GlobalNavSettingsService {
	private settings = new BehaviorSubject<GlobalNavSettings>(defaultSettings);

	update(newSettings: Partial<GlobalNavSettings>): void {
		const settings = this.settings.getValue();
		this.settings.next({ ...settings, ...newSettings });
	}

	getSettings(): Observable<GlobalNavSettings> {
		return this.settings.asObservable();
	}
}
