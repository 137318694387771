<ng-container *cdxEmbedded>
	<cdx-svg-icon
		size="default"
		name="arrow-left"
		class="search-back"
		fill="currentColor"
		data-cy="search-back-arrow"
		aria-label="Close search input"
		(click)="blurButtonClick()"
	></cdx-svg-icon>
</ng-container>
<form
	method="get"
	class="search-box-layout"
	[ngClass]="{
		'search-box-layout--embeddedNoIcon': isEmbeddedInApp && !(scanToTrolleyEnabled$ | async)
	}"
	[formGroup]="searchForm"
>
	<label for="search" class="sr-only"
		>Search for a {{ searchType === searchTypeEnum.Grocery ? 'product' : 'recipe' }}</label
	>
	<global-nav-search-form-autocomplete
		(typedValue)="fetchSearchSuggestions($event)"
		[searchResults]="searchSuggestions"
		[formGroup]="searchForm"
		displayKey="value"
		valueKey="id"
		[idPrefix]="suggestionIdentifierPrefix"
		[subnav]="subnav"
		[header]="resultsHeader"
		[tolerance]="0"
		(optionSelect)="handleSearchSuggestionSelection($event)"
		(optionFocus)="handleSearchSuggestionFocus($event)"
		(resubscribe)="subscribeToSearchSuggestionResponse()"
	>
		<form-input-with-icon-button
			autocomplete="off"
			autocorrect="off"
			class="search-text"
			formControlName="search"
			[placeholder]="placeholder"
			maxlength="40"
			name="search"
			[size]="size"
			title="Search"
			inputType="search"
			value=""
			#input
			(keydown)="onKeyDownEnter($event)"
			[activeDescendant]="focussedSuggestion"
		>
			<button
				btn
				cdxButton
				fillStyle="flat"
				type="submit"
				class="search-button"
				id="searchButton"
				aria-label="Submit search"
				(click)="submitButtonClick()"
				data-cy="search-icon"
			>
				<cdx-svg-icon size="small" name="search-icon" fill="currentColor" data-cy="search-icon"></cdx-svg-icon>
			</button>
		</form-input-with-icon-button>
	</global-nav-search-form-autocomplete>

	<button
		cdxButton
		fillStyle="link"
		id="searchClear"
		type="button"
		aria-label="Clear search"
		*ngIf="hasInputtedText$ | async"
		(mousedown)="clearButtonClick($event)"
		(click)="clearButtonClick($event)"
	>
		<cdx-svg-icon name="cross" size="extra-small" fill="dark"></cdx-svg-icon>
	</button>

	<div class="drop-container">
		<label for="SearchType" class="sr-only">Select a search type</label>
		<select
			class="search-option _selectedCatalog"
			id="SearchType"
			name="searchType"
			title="Search Scope"
			formControlName="searchType"
		>
			<option *ngFor="let searchtype of searchTypeEnum | keyvalue" [value]="searchtype.value">
				{{ searchtype.value }}
			</option>
		</select>
	</div>
</form>
<ng-container *ngIf="(scanToTrolleyEnabled$ | async) && searchType === searchTypeEnum.Grocery">
	<ng-container *ngTemplateOutlet="scanToTrolleyTemplate"> </ng-container>
</ng-container>

<ng-template #scanToTrolleyTemplate>
	<button
		cdxButton
		fillStyle="flat"
		id="scanToTrolleyIcon"
		type="button"
		aria-label="Scan to trolley"
		(click)="scanToTrolley()"
	>
		<cdx-svg-icon name="scan-to-trolley" fill="currentColor" data-cy="scan-to-trolley-icon"></cdx-svg-icon>
	</button>
</ng-template>
