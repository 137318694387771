import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LegalBarComponent } from '../legal-bar/legal-bar.component';
import { FamilyBarComponent } from '../family-bar/family-bar.component';
import { FooterLinksComponent } from '../footer-links/footer-links.component';

@Component({
	selector: 'global-nav-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FooterLinksComponent, FamilyBarComponent, LegalBarComponent]
})
export class FooterComponent {
	@Input() simplified = false;
}
