import { AsyncPipe, isPlatformServer, NgIf } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	InjectionToken,
	OnInit,
	Optional,
	PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	AppSettingsService,
	ButtonComponent,
	CustomWindow,
	FeatureService,
	LoggingService,
	MaybeExternalLinkDirective,
	NotificationType,
	TrackEventDirective,
	TrackingEvent,
	TRADER_BASE_URL,
	WINDOW,
} from '@woolworthsnz/styleguide';
import { GlobalNavSettingsService } from '../../services/global-nav-settings.service';
import { REGISTRATION_SHOPPER_DETAILS_PATH } from '@woolworthsnz/shop';
import { ContextResponse } from '@woolworthsnz/trader-api';

@Component({
	selector: 'global-nav-sign-in',
	template: `
		<section class="signIn">
			<h2 i18n="@@globalNav-signIn-welcome">Welcome</h2>
			<button
				class="signIn-button mobileNav-signInBtn"
				i18n="@@globalNav-signIn-signInButton"
				cdxButton
				size="large"
				[fullWidth]="true"
				[cdxTrackEvent]="{
					event: trackingEvent.NotificationEvent,
					type: notificationType.Global,
					name: 'sign_in',
					value: 'Sign In',
				}"
				(click)="signIn()"
			>
				Sign In
			</button>
			<p class="signIn-linkItem">
				<a
					cdxMaybeExternal
					[maybeExternalCurrentTab]="(externalLinksInCurrentTab$ | async) ?? false"
					i18n="@@globalNav-signIn-registerShoppingText"
					[cdxTrackEvent]="{
						event: trackingEvent.NotificationEvent,
						type: notificationType.Global,
						name: 'sign_in',
						value: 'Register for Online shopping',
					}"
					href="{{ traderRegisterLink }}"
					>Register for online shopping</a
				>
			</p>
		</section>
	`,
	styleUrls: ['./sign-in.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ButtonComponent, TrackEventDirective, MaybeExternalLinkDirective, NgIf, AsyncPipe],
})
export class SignInComponent implements OnInit {
	public externalLinksInCurrentTab$: Observable<boolean>;
	public trackingEvent = TrackingEvent;
	public notificationType = NotificationType;

	constructor(
		private globalNavSettings: GlobalNavSettingsService,
		private appSettingsService: AppSettingsService,
		protected router: Router,
		private loggingService: LoggingService,
		private featureService: FeatureService,
		@Inject(WINDOW) private window: CustomWindow,
		@Inject(PLATFORM_ID) private platform: InjectionToken<Object>,
		@Optional() @Inject(TRADER_BASE_URL) private traderBaseUrl?: string
	) {}

	get traderRegisterLink(): string {
		if (isPlatformServer(this.platform)) {
			return '';
		}
		if (this.appSettingsService.useSSU()) {
			const redirectUrl = `${this.window.origin}/shop/${REGISTRATION_SHOPPER_DETAILS_PATH}`;
			return `${this.window.origin}${this.appSettingsService.getEndpoint(
				'oidcSignInEndpoint'
			)}?redirectUrl=${encodeURIComponent(this.appSettingsService.getAuthRedirectUrl(redirectUrl))}`;
		} else {
			return `${this.traderBaseUrl ?? ''}/shop/register`;
		}
	}

	ngOnInit(): void {
		this.externalLinksInCurrentTab$ = this.globalNavSettings
			.getSettings()
			.pipe(map((settings) => settings.externalLinksInCurrentTab));
	}

	signIn(): void {
		if (isPlatformServer(this.platform)) {
			return;
		}

		if (this.appSettingsService.useSSU()) {
			const redirectUrl = `${this.appSettingsService.getEndpoint(
				'oidcSignInEndpoint'
			)}?redirectUrl=${encodeURIComponent(
				this.appSettingsService.getAuthRedirectUrl(this.window.location.href)
			)}`;
			if (this.featureService.isFeatureEnabled(ContextResponse.EnabledFeaturesEnum.RedirectValidation)) {
				this.loggingService.trackEvent('RedirectValidation', {
					shopper: 'empty',
					detail: 'redirect in sign in page',
					href: redirectUrl,
				});
			}

			this.window.location.href = redirectUrl;
		} else {
			this.router.navigateByUrl('/shop/securelogin');
		}
	}
}
