<div class="howWhereWhenBar">
	<cdx-svg-icon		
		[name]="iconName$ | async"
		size="small"
		fill="dark" 
		display="inline"
	/>	
	{{ informationMessage$ | async }}		
	<button 
		type="button"
		cdxButton 
		fillStyle="link" 
		(click)="handleActionButtonClick()" 			
		aria-label="Change location"
		class="actionButtonLink"
		>{{ actionMessage$ | async }}
	</button>
</div>
