<a
	cdxButton
	[attr.aria-label]="checkoutAriaLabel"
	[rounded]="true"
	[inline]="true"
	[fillStyle]="globalNavButtonFillStyle"
	class="basketTotals-link basketTotals-link--small"
	maybeExternal
	[maybeExternalCurrentTab]="externalLinksInCurrentTab$ | async"
	[href]="trolleyLinkUrl"
	[cdxTrackEvent]="{
		event: trackingEvent.NotificationEvent,
		type: notificationType.Global,
		name: 'reviewtrolley'
	}"
>
	<div class="basketTotals-amountToPay">
		<! -- we put an *ngFor so it can trigger a leave / enter event while preserving the old value. That way it can fade out with the old value and slide up with the new value -->
		<span [@fadeSlideUp]="amountToPay" *ngFor="let value of [amountToPay]">{{ amountToPay }}</span>
	</div>
	<ng-container>
		<cdx-svg-icon name="trolley" [fill]="globalNavIconColor"></cdx-svg-icon>
		<span
			class="basketTotals-lozenge basketTotals-lozenge--invert basketTotals-lozenge--lower basketTotals-lozenge--bold"
			[attr.aria-label]="ariaLabel"
			[@lozengeZoomInOut]="totalItems"
			>{{ totalItems }}</span
		>
	</ng-container>
</a>
