<nav class="quickNav">
	<ul>
		<li *ngFor="let link of quickNavLinks; trackBy: trackByFn">
			<a
				[cdxTrackEvent]="getTrackingData(link.text)"
				[href]="link.url"
				maybeExternal
				[maybeExternalCurrentTab]="externalLinksInCurrentTab || link.target === 'self'"
				cdxButton
				fillStyle="tertiaryFlat"
				startSlotSize="small"
				size="small"
				>{{ link.text }}</a
			>
		</li>
		<li #rewardsModalTarget>
			<a
				[cdxTrackEvent]="getTrackingData(everydayRewardsLink.text)"
				[href]="everydayRewardsLink.url"
				maybeExternal
				[maybeExternalCurrentTab]="externalLinksInCurrentTab || everydayRewardsLink.target === 'self'"
				cdxButton
				fillStyle="tertiaryFlat"
				startSlotSize="small"
				size="small"
				data-testid="rewards-link"
				>{{ everydayRewardsLink.text }}</a
			>
		</li>

		<li *ngIf="isLoggedIn$ | async; else signIn">
			<cdx-popup
				padding="none"
				popupActiveClass="popupOpen"
				[popupTracking]="popupTrackingLoggedIn"
				popupId="quickNavLoggedInPopup"
				popupPosition="end"
				(modalOpen)="handleModalOpen($event)"
			>
				<button
					type="button"
					i18n="@@globalNav-quickNav-greeting"
					cdxButton
					cdxPopupTarget
					fillStyle="tertiaryFlat"
					startSlotSize="small"
					size="small"
					[endSlotActive]="true"
				>
					Kia ora<ng-container *ngIf="firstName$ | async; let firstName">, {{ firstName }}</ng-container>
					<cdx-svg-icon name="chevron-bottom" size="small" fill="currentColor" end></cdx-svg-icon>
				</button>
				<global-nav-my-account
					[refresh]="refreshAccountMenu | async"
					[externalLinksInCurrentTab]="externalLinksInCurrentTab"
					cardContent
					(signOut)="handleLogout()"
				>
				</global-nav-my-account>
			</cdx-popup>
		</li>
		<ng-template #signIn>
			<li>
				<cdx-popup
					popupPosition="end"
					popupActiveClass="popupOpen"
					[popupTracking]="popupTrackingLoggedOut"
					popupId="quickNavSignInPopup"
				>
					<button
						type="button"
						i18n="@@globalNav-quickNav-signInOrRegister"
						cdxButton
						cdxPopupTarget
						fillStyle="tertiaryFlat"
						startSlotSize="small"
						size="small"
					>
						Sign in or Register
					</button>
					<global-nav-sign-in cardContent></global-nav-sign-in>
				</cdx-popup>
			</li>
		</ng-template>
	</ul>
</nav>
