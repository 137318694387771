import { AsyncPipe, CurrencyPipe, NgIf, TitleCasePipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Optional,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import {
	kebabCase,
	CapitalizeSpecificWordsPipe,
	SrPunctuationPipe,
	DeferLoadDirective,
	sentenceCase,
	JoinPipe,
	ButtonComponent,
	NavigationItemGroupWithAnalytics,
	FlagKey,
	FlagService,
	MegaMenuProductDiscovery,
	PromoTileImpressionArgs,
	TrackImpressionDirective,
	TRADER_BASE_URL,
} from '@woolworthsnz/styleguide';
import { BehaviorSubject, forkJoin, Observable, Subject, takeUntil, tap, combineLatest } from 'rxjs';
import { ProductResponse, ProductDetailsResponse } from '@woolworthsnz/trader-api';
import { ProductService } from '../../services/product-service';
import { TealiumUtagService } from '@woolworthsnz/analytics';

@Component({
	selector: 'global-nav-feature-mega-menu-long-tile',
	templateUrl: './feature-mega-menu-long-tile.component.html',
	styleUrls: ['./feature-mega-menu-long-tile.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		AsyncPipe,
		CurrencyPipe,
		NgIf,
		RouterLink,
		TitleCasePipe,
		CapitalizeSpecificWordsPipe,
		SrPunctuationPipe,
		DeferLoadDirective,
		JoinPipe,
		ButtonComponent,
		TrackImpressionDirective,
	],
})
export class FeatureMegaMenuLongTileComponent implements OnInit, OnDestroy {
	@Input()
	public aislesNavItems$ = new BehaviorSubject<NavigationItemGroupWithAnalytics[] | undefined>(undefined);
	@Input()
	public shelvesNavItems$ = new BehaviorSubject<NavigationItemGroupWithAnalytics[] | undefined>(undefined);
	@Input()
	public width: number;
	public isVisible$ = new BehaviorSubject<boolean>(false);
	public hrefLink = '';
	private destroyed$: Subject<boolean> = new Subject();
	@Output() productClicked = new EventEmitter<{ department: string; aisle: string | undefined; sku: string }>();
	promoImpressionArgs: Map<string, PromoTileImpressionArgs> = new Map();
	private megaMenuProductDiscoveryVarient$: Observable<string>;

	private departments = [
		{ department: 'Pantry', sku: '297515' },
		{ department: 'Fridge & Deli', sku: '281677' },
	];
	private aisles = [
		{ aisle: 'Snacks & Sweets', sku: '297515' },
		{ aisle: 'Eggs, Butter & Spreads', sku: '281677' },
	];
	private products = new Map<string, ProductDetailsResponse>();

	constructor(
		public productService: ProductService,
		private flagService: FlagService,
		private tealiumUtagService: TealiumUtagService,
		@Optional() @Inject(TRADER_BASE_URL) public baseDomain: string
	) {}

	get product(): ProductDetailsResponse {
		return this.productService.state.product;
	}

	get cupPrice(): string | false {
		return this.productService.cupPrice;
	}

	get isKgItem(): boolean {
		return this.productService.unit === ProductResponse.UnitEnum.Kg;
	}

	get supportsBothEachAndKgPricing(): boolean | undefined {
		return this.productService.supportsBothEachAndKgPricing;
	}

	get isEachKgMeatProduct(): boolean | undefined {
		return this.supportsBothEachAndKgPricing;
	}

	get productTitle(): string | undefined {
		return this.isEachKgMeatProduct && this.product?.size?.volumeSize
			? `${this.product?.name} ${this.product?.size?.volumeSize}`
			: this.product?.name;
	}

	get unit(): ProductResponse.UnitEnum | undefined {
		if (this.productService.supportsBothEachAndKgPricing) {
			return this.productService.selectedPurchasingUnit;
		}

		return this.productService.unit;
	}

	get nameInKebabCase(): string {
		return kebabCase(this.product?.name || '');
	}

	get src(): string {
		return (this.product?.images || [])[0].small || '';
	}

	get volumeSize(): string | false | undefined {
		return (
			this.product?.size?.volumeSize &&
			!this.product.supportsBothEachAndKgPricing &&
			sentenceCase(this.product?.size?.volumeSize)
		);
	}

	get packageType(): string | false | undefined {
		return (
			this.product?.size?.packageType &&
			!this.product.supportsBothEachAndKgPricing &&
			sentenceCase(this.product?.size?.packageType)
		);
	}

	get ariaLabels(): string[] {
		const labels: string[] = [];
		if (this.packageType) {
			labels.push(`Package type ${this.packageType}.`);
		}
		if (this.volumeSize && !this.isKgItem) {
			labels.push(`Volume size ${this.volumeSize}.`);
		}
		if (this.cupPrice) {
			labels.push(`Cup price ${this.cupPrice}.`);
		}
		return labels;
	}

	ngOnInit(): void {
		this.megaMenuProductDiscoveryVarient$ = this.flagService.getVariationKey(FlagKey.megaMenuProductDiscovery);

		combineLatest([
			this.loadProductDetails(),
			this.megaMenuProductDiscoveryVarient$,
			this.aislesNavItems$,
			this.shelvesNavItems$,
		])
			.pipe(takeUntil(this.destroyed$))
			.subscribe(([_, megaMenuProductDiscoveryVarient, aislesNavItems, shelvesNavItems]) => {
				if (megaMenuProductDiscoveryVarient === MegaMenuProductDiscovery.Variation3) {
					this.showDepartmentProducts(aislesNavItems, shelvesNavItems);
				} else if (megaMenuProductDiscoveryVarient === MegaMenuProductDiscovery.Variation4) {
					this.showAislesProducts(shelvesNavItems);
				}
			});
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
		this.isVisible$.complete();
	}

	private setupPromoImpressions(
		promoTileName: string,
		departmentLabel: string | undefined,
		aisleLabel: string | undefined
	): void {
		const promotionId = this.getPromotionId(departmentLabel, aisleLabel);
		let creativeName = 'Browse Menu';
		if (departmentLabel) {
			creativeName += ` | ${departmentLabel}`;
		}
		if (aisleLabel) {
			creativeName += ` | ${aisleLabel}`;
		}
		const oddBunchData: PromoTileImpressionArgs = {
			creativeName: creativeName,
			creativeSlot: '4',
			promotionId: promotionId,
			promotionName: promoTileName,
		};

		this.promoImpressionArgs.set(promoTileName, oddBunchData);
	}

	private getPromotionId(departmentLabel: string | undefined, aisleLabel: string | undefined): string {
		if (!aisleLabel) {
			if (departmentLabel === 'Fridge & Deli') {
				return 'AB166-5';
			}
			if (departmentLabel === 'Pantry') {
				return 'AB166-6';
			}
		} else {
			if (departmentLabel === 'Fridge & Deli' && aisleLabel === 'Eggs, Butter & Spreads') {
				return 'AB166-7';
			}
			if (departmentLabel === 'Pantry' && aisleLabel === 'Snacks & Sweets') {
				return 'AB166-8';
			}
		}

		return '';
	}

	onPromoTileClicked(promoTileName: string): void {
		const aislesNavItems = this.aislesNavItems$.getValue();
		const shelvesNavItems = this.shelvesNavItems$.getValue();

		let departmentLabel: string | undefined;
		let aisleLabel: string | undefined;

		if (aislesNavItems && aislesNavItems.length > 0) {
			departmentLabel = aislesNavItems[0]?.label;
		}
		if (shelvesNavItems && shelvesNavItems.length > 0) {
			aisleLabel = shelvesNavItems[0]?.label;
		}

		let creativeName = 'Browse Menu';
		if (departmentLabel) {
			creativeName += ` | ${departmentLabel}`;
		}
		if (aisleLabel) {
			creativeName += ` | ${aisleLabel}`;
		}

		const tileId = this.getPromotionId(departmentLabel, aisleLabel);

		this.tealiumUtagService.link(
			{
				event: 'ab_test',
				tealium_event: 'ab_test',
				ecommerce: {
					test_name: 'AB-166',
					test_event: 'Add to trolley click',
					test_component: creativeName,
					test_id: tileId,
					test_content: promoTileName,
				},
			},
			true
		);
	}

	onProductTileClick(department: string, aisle: string | undefined, sku: string): void {
		this.productClicked.emit({ department, aisle, sku });
	}

	private loadProductDetails(): Observable<ProductDetailsResponse[]> {
		const productDetailsRequests = this.departments.map((department) =>
			this.productService.getProductDetail(department.sku).pipe(
				takeUntil(this.destroyed$),
				tap((response: ProductDetailsResponse) => {
					if (response.sku && response.name) {
						this.products.set(response.sku, response);
					}
				})
			)
		);
		return forkJoin(productDetailsRequests);
	}

	private showDepartmentProducts(
		aislesNavItems: NavigationItemGroupWithAnalytics[] | undefined,
		shelvesNavItems: NavigationItemGroupWithAnalytics[] | undefined
	): void {
		this.clearPromoImpressions();
		const department = this.departments.find(
			(d) => aislesNavItems && aislesNavItems[0]?.label && d.department === aislesNavItems[0]?.label
		);
		if (department && !shelvesNavItems) {
			this.updateProductState(department.sku);
			const departmentLabel = department.department;
			const aisleLabel = undefined;
			this.onProductTileClick(departmentLabel, aisleLabel, department.sku);

			// Force trigger promo impressions by resetting and then setting up
			setTimeout(() => {
				this.setupPromoImpressions(this.product?.name ?? '', departmentLabel, aisleLabel);
				this.isVisible$.next(true); // Ensure visibility is set after promo impressions
			}, 0);
		} else {
			this.isVisible$.next(false);
		}
	}

	private clearPromoImpressions(): void {
		// This method clears any previous promo impressions before setting up new ones
		this.promoImpressionArgs.clear();
		this.isVisible$.next(false);
	}

	private showAislesProducts(shelvesNavItems: NavigationItemGroupWithAnalytics[] | undefined): void {
		const aisle = this.aisles.find(
			(d) => shelvesNavItems && shelvesNavItems[0]?.label && d.aisle === shelvesNavItems[0]?.label
		);
		if (aisle) {
			this.updateProductState(aisle.sku);
			const departmentLabel = this.departments.find((dept) => dept.sku === aisle.sku)?.department ?? 'undefined';
			const aisleLabel = aisle.aisle;
			this.onProductTileClick(departmentLabel, aisleLabel, aisle.sku);

			this.setupPromoImpressions(this.product?.name ?? '', departmentLabel, aisleLabel);
			this.isVisible$.next(true);
		} else {
			this.isVisible$.next(false);
		}
	}

	private updateProductState(sku: string): void {
		const product = this.products.get(sku);
		this.productService.setState({ product });
		this.hrefLink = `${this.baseDomain ?? ''}/shop/productdetails?stockcode=${product?.sku}&name=${this.nameInKebabCase}`;
	}
}
