<div *ngIf="(isVisible$ | async)">
	<div class="browse-product-stamp">
		<a
			[href]="hrefLink"
			class="product-entry"
		>
			<div class="browse-product-stamp-mainImage">
				<figure>
					<img
						[width]="width"
						[alt]="product?.name"
						deferLoad
						class="product-image"
						[src]="product?.src"
						[cdxTrackImpression]="promoImpressionArgs.get(product?.name)"
					/>
				</figure>
			</div>
		</a>
	</div>
</div>
