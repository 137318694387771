import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Optional,
	Output,
} from '@angular/core';
import {
	ACCOUNT_ONECARD_FOR_OLS_PATH,
	RECIPES_PATH,
	NZ_GROCERY_WHOLESALERS_PAGE_PATH,
	EDR_LANDING_PAGE_PATH,
} from '@woolworthsnz/shop';
import {
	ACCOUNT_BASE_URL,
	BreakPointService,
	Device,
	FlagKey,
	FlagService,
	MaybeExternalLinkDirective,
	NotificationType,
	TrackingData,
	TrackingEvent,
	TrackEventDirective,
	TRADER_BASE_URL,
	AppSettingsService,
} from '@woolworthsnz/styleguide';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalNavSettingsService } from '../../services/global-nav-settings.service';
import { QuickNavComponent } from '../quick-nav/quick-nav.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'global-nav-family-bar',
	templateUrl: './family-bar.component.html',
	styleUrls: ['./family-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, QuickNavComponent, TrackEventDirective, MaybeExternalLinkDirective, AsyncPipe],
})
export class FamilyBarComponent {
	@Output() public logout = new EventEmitter<void>();

	public newCustomerSelfService$: Observable<boolean>;
	public externalLinksInCurrentTab$: Observable<boolean>;
	public onecardLink = `/${ACCOUNT_ONECARD_FOR_OLS_PATH}`;
	public isSmallScreen = false;
	public expressText$: Observable<string>;
	public edrLandingPageUrl = `/${EDR_LANDING_PAGE_PATH}`;

	constructor(
		private flagService: FlagService,
		private breakpointService: BreakPointService,
		private globalNavSettings: GlobalNavSettingsService,
		private appSettingsService: AppSettingsService,
		@Optional() @Inject(TRADER_BASE_URL) private traderBaseUrl?: string,
		@Optional() @Inject(ACCOUNT_BASE_URL) private accountBaseUrl?: string
	) {
		this.assignOneCardLink();

		this.breakpointService.device$.pipe(takeUntilDestroyed()).subscribe((device: Device) => {
			this.isSmallScreen = ![Device.LARGE, Device.XLARGE].includes(device);
		});

		this.externalLinksInCurrentTab$ = this.globalNavSettings
			.getSettings()
			.pipe(map((settings) => settings.externalLinksInCurrentTab));

		this.expressText$ = this.flagService
			.isFlagEnabled(FlagKey.expressLandingPageNew)
			.pipe(map((isActive) => (isActive ? 'Express' : 'Express Pick up')));


		this.edrLandingPageUrl = `${this.appSettingsService.getSetting('accountBaseUrl') ?? ''
			}/${EDR_LANDING_PAGE_PATH}`;
	}

	get recipesLink(): string {
		return `${this.traderBaseUrl ?? ''}/${RECIPES_PATH}`;
	}

	get nzGroceryWholesalersLink(): string {
		return `${this.traderBaseUrl ?? ''}/${NZ_GROCERY_WHOLESALERS_PAGE_PATH}`;
	}

	public handleLogout(): void {
		this.logout.emit();
	}

	public getTrackingData(name: string): TrackingData {
		return {
			event: TrackingEvent.NotificationEvent,
			type: NotificationType.FamilyBar,
			name,
		};
	}

	private assignOneCardLink(): void {
		if (this.accountBaseUrl) {
			this.onecardLink = `${this.accountBaseUrl}/${ACCOUNT_ONECARD_FOR_OLS_PATH}`;
		} else {
			this.onecardLink = `/${ACCOUNT_ONECARD_FOR_OLS_PATH}`;
		}
	}
}
