export const hasSubmenu = (element: HTMLElement): boolean => element.getAttribute('aria-haspopup') === 'true';

export const setElementTabbable = (element: HTMLElement | undefined, isTabbable: boolean): void => {
	element?.setAttribute('tabindex', isTabbable ? '0' : '-1');
};

export const focusOnCurrentMenuItem = (currentMenuItem: HTMLElement | undefined): void => {
	setElementTabbable(currentMenuItem, true);
	currentMenuItem?.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'nearest' });
	currentMenuItem?.focus();
};
