import { Injectable } from '@angular/core';

export enum Tag {
	head = 'head',
	body = 'body',
}

@Injectable({
	providedIn: 'root',
})
export class InjectScriptService {
	injectScript(document: Document, scriptLocation: string, onLoad?: () => void, injectInto: Tag = Tag.body): void {
		if (document.querySelector(`script[src="${scriptLocation}"]`)) {
			return;
		}

		const script = document.createElement('script');

		script.defer = true;
		script.src = scriptLocation;
		script.type = 'text/javascript';
		script.onload = () => onLoad?.();

		document.getElementsByTagName(injectInto)[0].appendChild(script);
	}

	injectNoscript(document: Document, scriptLocation: string, injectInto: Tag = Tag.body): void {
		if (document.querySelector(`iframe[src="${scriptLocation}"]`)) {
			return;
		}

		const noscript = document.createElement('noscript');
		const iframe = document.createElement('iframe');

		iframe.src = scriptLocation;
		iframe.style.width = '100px';
		iframe.style.height = '100px';
		iframe.style.border = '0';
		iframe.style.position = 'absolute';
		iframe.style.top = '-5000px';
		noscript.appendChild(iframe);

		document.getElementsByTagName(injectInto)[0].appendChild(noscript);
	}
}
